<template>
    <div class="vx-row">
        <div class="toggleUserList outside" v-if="!userListActive">
            <feather-icon icon="DiscIcon" class="inline-block mr-2" svgClasses="w-4 h-4" @click="toggleUserList"  />
        </div>
        <div class="vx-col md:w-1/4 w-full mb-1" v-if="userListActive">
            <vx-card title="Mitarbeiter">
                <div class="toggleUserList">
                    <feather-icon icon="DiscIcon" class="inline-block mr-2" svgClasses="w-4 h-4" @click="toggleUserList"  />
                </div>
                <div class="vx-row mb-6 mt-0">
                    <div class="vx-col w-full">
                        <vx-input-group class="mb-0">
                            <vs-input v-model="searchFilter" placeholder="Suche ..." name="userSearchTerm" @input="filterUsers" />
                        </vx-input-group>
                    </div>
                </div>

                <vs-list>
                    <vs-list-header title="Mitarbeiter Liste"></vs-list-header>

                    <vs-list-item v-for="user in filteredUsers" :title="user.detail.first_name + ' ' + user.detail.last_name " :subtitle="user.email">
                        <vs-button  size="small" @click="selectUser(user)">anschauen</vs-button>
                    </vs-list-item>
                </vs-list>

            </vx-card>
        </div>

        <div class="vx-col w-full mb-1"  v-bind:class="{ 'md:w-3/4': userListActive, 'md:w-4/4': !userListActive }">
            <vx-card>

                <vs-alert :active="!selectedUser">
                    Kein Mitarbeiter ausgewählt
                </vs-alert>

                <div v-if="selectedUser">

                    <vs-row class="monthSelection">
                      <vs-col vs-align="center" vs-w="1">
                        <div class="monthSelectionEntry "  @click="changeSelectedYearOrDate('2025',month)"  :class="{ 'active':  year == '2025' }">
                          <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                          2026
                        </div>
                      </vs-col>
                      <vs-col vs-align="center" vs-w="1">
                          <div class="monthSelectionEntry "  @click="changeSelectedYearOrDate('2025',month)"  :class="{ 'active':  year == '2025' }">
                              <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                              2025
                          </div>
                      </vs-col>
                      <vs-col vs-align="center" vs-w="1">
                          <div class="monthSelectionEntry "  @click="changeSelectedYearOrDate('2024',month)"  :class="{ 'active':  year == '2024' }">
                              <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                              2024
                          </div>
                      </vs-col>
                      <vs-col vs-align="center" vs-w="1">
                        <div class="monthSelectionEntry "  @click="changeSelectedYearOrDate('2023',month)"  :class="{ 'active':  year == '2023' }">
                          <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                          2023
                        </div>
                      </vs-col>
                      <vs-col vs-align="center" vs-w="1">
                        <div class="monthSelectionEntry "  @click="changeSelectedYearOrDate('2022',month)"  :class="{ 'active':  year == '2022' }">
                          <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                          2022
                        </div>
                      </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry "  @click="changeSelectedYearOrDate('2021',month)"  :class="{ 'active':  year == '2021' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                2021
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry " @click="changeSelectedYearOrDate('2020',month)" :class="{ 'active':  year == '2020' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                2020
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry " @click="changeSelectedYearOrDate('2019',month)" :class="{ 'active':  year == '2019' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                2019
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1" >
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate('2018',month)" :class="{ 'active':  year == '2018' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                2018
                            </div>
                        </vs-col>
                    </vs-row>


                    <vs-row class="monthSelection">
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'01')" :class="{ 'active':  month == '01' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Jan.
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'02')" :class="{ 'active':  month == '02' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Feb.
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'03')" :class="{ 'active':  month == '03' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                März
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'04')" :class="{ 'active':  month == '04' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                April
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'05')" :class="{ 'active':  month == '05' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Mai
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'06')" :class="{ 'active':  month == '06' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Juni
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'07')" :class="{ 'active':  month == '07' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Juli
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'08')" :class="{ 'active':  month == '08' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Aug.
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'09')" :class="{ 'active':  month == '09' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Sept.
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'10')" :class="{ 'active':  month == '10' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Okt.
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'11')" :class="{ 'active':  month == '11' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Nov.
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="1">
                            <div class="monthSelectionEntry" @click="changeSelectedYearOrDate(year,'12')" :class="{ 'active':  month == '12' }">
                                <feather-icon icon="CalendarIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" />
                                Dez.
                            </div>
                        </vs-col>

                    </vs-row>

                  <span @click="showStatisticsHistory = !showStatisticsHistory">Verlauf anzeigen</span>
                  <vs-row v-if="showStatisticsHistory">

                    <StatisticsHistory></StatisticsHistory>

                  </vs-row>
                </div>
            </vx-card>

            <vx-card class="commissionsareaNotfound" v-if="!dataSettlement && selectedUser">
                <vs-alert :active="!dataSettlement">
                    Keine Provisionsabrechnung gefunden.
                    <vs-button  color="dark" size="small"  @click="createSettlement(selectedUser.id,month,year)">Jetzt erstellen</vs-button>
                </vs-alert>
            </vx-card>

            <vx-card class="commissionsarea" v-if="dataSettlement">
                <vs-row class="Settlement" >
                    <vs-col vs-align="center" vs-w="9">
                        <h2>Provisionsabrechnung {{month}}/{{year}}</h2><br>
                        <h3>{{selectedUser.detail.first_name}} {{selectedUser.detail.last_name}}</h3>

                        <span><i>Created  {{dataSettlement.created_at}} || Updated: {{dataSettlement.updated_at}}</i></span>

                        <p>&nbsp;</p>
                        <vs-textarea class="notice" label="Notiz ..."
                                     v-model="dataSettlement.notice" :disabled="dataSettlement.status == 3 "/>


                    </vs-col>
                    <vs-col vs-align="right" vs-w="3">

                        <div class="commissionsStatus draft" v-if="dataSettlement.status == 1">
                            Entwurf
                        </div>
                        <div class="commissionsStatus ready" v-if="dataSettlement.status == 2">
                            Bereit zur Prüfung
                        </div>
                        <div class="commissionsStatus success" v-if="dataSettlement.status == 3">
                            Abgeschlossen
                        </div>



                    </vs-col>
                </vs-row>

                <vs-row class="commissionsPositions">
                    <h3>Positionen</h3>


                    <vs-row v-if="dataSettlement.status != 3 ">
                        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="4" vs-offset="8">
                            <vx-tooltip text="Unabhängige Provision hinzufügen" position="left" class="AddCommissionPopup">
                                <span class="ml-3 mb-4 link AddCommissionPopup" @click="AddCommissionPopup" title="">Hinzufügen</span>
                            </vx-tooltip>
                            <vs-button class="ml-3 mb-4 vs-button-warning vs-button-filled" @click="AddOpenCommissionPopup">Virtueller Umsatz hinzufügen</vs-button>
                            <vs-button class="ml-3 mb-4 vs-button-success vs-button-filled" @click="AddOpenCommissionPopup">Offene Positionen finden</vs-button>
                        </vs-col>
                    </vs-row>
                    <vue-bootstrap4-table v-if="dataSettlementItems"
                            :rows="dataSettlementItems"
                            :columns="columns"
                            :config="config"
                            :classes="{table: 'row-select'}"
                            @on-change-query="onChangeQuery"
                            :total-rows="total_rows">

                        <template slot="amount" slot-scope="props">
                            <span class="vbt-table-td">
                              {{props.row.amount}}  {{getVolumeUnitNameById(props.row.volume_unit)}}
                            </span>
                        </template>

                        <template slot="percent_performance" slot-scope="props">
                            <span class="vbt-table-td">
                             {{parsePercent(props.row.percent_performance)}}%
                            </span>
                        </template>

                        <template slot="attachments" slot-scope="props">
                            <span class="vbt-table-td">

                                <router-link :to="'/accounting/'+props.row.accounting_id" v-if="props.row.accounting_id">Abrechnungspos.<br></router-link>
                                <router-link :to="'/invoices/view/'+props.row.order_id" v-if="props.row.order_id">Rechnung<br></router-link>
                                <router-link :to="'/contracts/'+props.row.contract_id" v-if="props.row.contract_id">Vertrag<br></router-link>
                                <router-link :to="'/products-and-services/'+props.row.article_id" v-if="props.row.article_id">Artikel<br></router-link>
                                <router-link :to="'/offers/'+props.row.offer_id+'/preview/'" v-if="props.row.offer_id">Angebot<br></router-link>
                                <span v-if="props.row.commision_type != 'reference_position'" class="badge-warning">{{props.row.commision_type}}</span>
                            </span>
                        </template>
                        <template slot="status" slot-scope="props">
                            <span class="vbt-table-td">
                                <vs-td class="commissionStatus">
                                    <span v-if="props.row.status_id == 1" class="draft">{{props.row.status.label}}</span>
                                    <span v-if="props.row.status_id == 2" class="readybyEmployee">{{props.row.status.label}}</span>
                                    <span v-if="props.row.status_id == 3" class="stop">{{props.row.status.label}}</span>
                                    <span v-if="props.row.status_id == 4" class="readybyChef">{{props.row.status.label}}</span>
                                    <span v-if="props.row.status_id == 5" class="readybyChef">{{props.row.status.label}}</span>
                                </vs-td>
                            </span>
                        </template>



                        <template slot="row_actions" slot-scope="props">
                            <div class="column-actions-group">

                                        <vx-tooltip text="Bearbeiten" position="left">
                                            <vs-button v-if="showEditButton(props.row.status)" color="success" type="filled" icon="edit"
                                                       size="small"
                                                       :to="'/invoices/'+props.row.id"></vs-button>
                                        </vx-tooltip>

                                        <vx-tooltip text="Zurück zu den offenen Positionen" position="left">
                                            <vs-button color="danger" type="flat" class="next-run"
                                                       icon="arrow_right_alt" size="small"
                                                      @click="removeFromSettlement(props.row)" v-if="dataSettlement.status != '3'" ></vs-button>
                                        </vx-tooltip>


                                        <vx-tooltip text="endgültig löschen" position="left">
                                            <vs-button color="warning"  type="flat"
                                                       icon="delete"
                                                       size="small"
                                                       @click="deleteCommision(props.row)" v-if="dataSettlement.status != '3'" ></vs-button>
                                        </vx-tooltip>


                            </div>
                            <div class="container-row actions">
                                    <feather-icon icon="MessageCircleIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" @click="openMessagesPopup(props.row)"   />
                                    <feather-icon icon="ArchiveIcon" v-if="dataSettlement.status != '3'"  class="inline-block mr-2" :class="{ 'active ArchiveIcon':  props.row.status_id == '1' }" svgClasses="w-4 h-4" @click="changeState(props.row, 1)" title="Status: Entwurf"   />
                                    <feather-icon icon="ThumbsUpIcon" v-if="dataSettlement.status != '3'"  class="inline-block mr-2" :class="{ 'active ThumbsUpIcon':  props.row.status_id == '2' }" svgClasses="w-4 h-4" @click="changeState(props.row, 2)"  title="Status: Freigegeben von Mitarbeiter"    />
                                    <feather-icon icon="PauseIcon" v-if="dataSettlement.status != '3'"  class="inline-block mr-2" :class="{ 'active PauseIcon':  props.row.status_id == '3' }" svgClasses="w-4 h-4" @click="changeState(props.row, 3)"  title="Status: In Klärung"    />
                                    <feather-icon icon="LoaderIcon" v-if="dataSettlement.status != '3' && edit_status_to_billed_commission_settlement == true "  class="inline-block mr-2" :class="{ 'active LoaderIcon':  props.row.status_id == '4' }" svgClasses="w-4 h-4" @click="changeState(props.row, 4)"  title="Status: Freigegeben von Chef"   />
                            </div>
                        </template>

                        <template slot="empty-results">
                            Keine Provisionen gefunden. Du musst erst offene Provisonen hinzufügen.
                        </template>

                        <template slot="table-last-row">
                            <tr class="vbt-row-summary">
                                <td colspan="4" align="left">
                                    <span class="vbt-table-td">{{total_rows}} Ergebnisse</span>
                                </td>

                            </tr>
                        </template>

                    </vue-bootstrap4-table>




                </vs-row>



                <vs-row>
                    <vs-col vs-align="right" vs-w="6" class="table-action" >
                        <v-select
                                v-model="selectedPositionAction"
                                :options="PositionActions"
                                autowidth
                                label="name"
                                class="w-2/3"
                                :searchable="false"
                                placeholder="Aktion für ausgewählte Positionen"
                                @input="onClickAllPositionAction"
                                v-if="dataSettlement.status != 3 ">
                        </v-select>
                    </vs-col>
                    <vs-col vs-align="right" vs-w="6" class="result-list">
                        <span>Gesamt (Prov): {{pageProvSum}}</span>
                        <i>Gesamt (Sum): {{pageSum}}</i>
                    </vs-col>
                </vs-row>


            </vx-card>

            <div class="flex mt-4 justify-end" v-if="dataSettlement">
                <vs-button @click="onClickSave('1')" v-if="dataSettlement.status == 1 ">Entwurf speichern</vs-button>
                <vs-button @click="onClickSave('1')" v-if="dataSettlement.status == 2 && edit_status_to_billed_commission_settlement == true" color="warning" type="border" >Zurück zum Entwurf</vs-button>
                <vs-button @click="onClickSave('2')" color="success" v-if="dataSettlement.status == 1  " >Speichern + Bereit zur Prüfung</vs-button>
                <vs-button @click="onClickSave('2')" v-if="dataSettlement.status == 2 && edit_status_to_billed_commission_settlement == true ">Speichern </vs-button>

                <vs-button @click="onClickSave('3')" color="success" v-if="dataSettlement.status == 2 && edit_status_to_billed_commission_settlement == true ">Provisionsabrechnung freigeben</vs-button>
                <vs-button @click="onClickSave('2')" v-if="dataSettlement.status == 3 && edit_status_to_billed_commission_settlement == true ">Status zurücksetzen </vs-button>

            </div>
        </div>

        <vs-popup classContent="popup-example"  title="Unabhängige Provision hinzufügen" :active.sync="commissionPopup" v-if="commissionPopup">

            <vs-col  vs-justify="left" vs-align="left" vs-w="12"class="pr-2 mb-8">
                Provisionstyp auswählen:
                <v-select
                        v-model="newCommissionsData.commision_type"
                        :options="commisionTypes"
                        autowidth
                        label="Typ"
                        :searchable="false"
                        placeholder="Typ"
                        @input="changeCommisionType">
                </v-select>


                <span class="text-danger text-sm" v-if="errors.has('commision_type')">{{ errors.first('commision_type') }}</span>
                <hr>
            </vs-col>

            <vs-row v-if="newCommissionsData.commision_type === 'other'">
                <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mt-3">
                    <mg-select v-model="newCommissionsData.client_id"  :options="clients"  name="clients" data-vv-as="Kunden"   placeholder="Optional: Kunde auswählen" track-by="id"  label="id" :custom-label="customClientLabel">

                        <template slot="singleLabel" slot-scope="{ option }">
                            <strong v-if="option.company_name">{{ option.company_name }}</strong>
                            <strong v-else>--</strong>
                        </template>

                        <template slot="option" slot-scope="props">
                                    <span v-if="props.option.company_name">
                                        {{props.option.company_name}}
                                      <small>(Kd. Nr.: {{props.option.customer_number}})</small>
                                    </span>
                                    <span v-else>--</span>
                        </template>

                    </mg-select>
                </vs-col>
            </vs-row>

            <vs-row v-if="newCommissionsData.commision_type === 'offer_position'">
                <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-8">
                    <mg-select v-model="newCommissionsData.offer_id"  :options="offers" v-validate="'required'" data-vv-as="Angebot"   placeholder="Angebot auswählen" track-by="id"  label="id" @select="onOfferChange"  :custom-label="customOfferLabel">

                        <template slot="singleLabel" slot-scope="{ option }">
                            <strong>Angebot {{ option.invoice_number }} </strong><small>{{option.title}}</small>
                        </template>

                        <template slot="option" slot-scope="props">
                                    <span>
                                        {{props.option.invoice_number}} <small>({{props.option.title}} - {{formatDate(props.option.order_date)}})</small></span>
                        </template>

                    </mg-select>
                </vs-col>

                <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-8" v-if="newCommissionsData.offer_id">
                    <mg-select v-model="newCommissionsData.offer_item_id"  :options="offerItems" v-validate="'required'" name="offerItem" data-vv-as="Angebotsposition"   placeholder="Position auswählen" track-by="id"  label="id" @select="onOfferItemChange" :custom-label="customOfferItemLabel">

                        <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.product_name }}</strong>
                        </template>

                        <template slot="option" slot-scope="props">
                                    <span>{{props.option.product_name}} ({{props.option.amount}} {{getVolumeUnitNameById(parseInt(props.option.volume_unit))}}) <br><small v-html="props.option.short_description">{{props.option.short_description}}</small></span>
                        </template>

                    </mg-select>
                </vs-col>
            </vs-row>


            <vs-row v-if="newCommissionsData.commision_type === 'contract_position'">
                <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-8">
                    <mg-select v-model="newCommissionsData.contract_id"  :options="contracts" v-validate="'required'" data-vv-as="Vertrag"   placeholder="Vertrag auswählen" track-by="id"  label="id" name="contract_id"   @select="onContractChange" :custom-label="customContractLabel">

                        <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{option.type.name}} <small v-if="option.client">{{option.client.company_name}}</small></strong>
                        </template>

                        <template slot="option" slot-scope="props">
                                    <span>
                                        {{props.option.type.name}} <small v-if="props.option.client">{{props.option.client.company_name}}</small></span>
                        </template>

                    </mg-select>
                    <span class="text-danger text-sm" v-if="errors.has('contract_id')">{{ errors.first('contract_id') }}</span>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col  vs-justify="left" vs-align="left" vs-w="12" class="mb-5">
                    <vs-input label-placeholder="Beschreibung" v-model="newCommissionsData.description" class="width-100" name="description" data-vv-as="Beschreibung"  v-validate="'required'"/>
                    <span class="text-danger text-sm" v-if="errors.has('description')">{{ errors.first('description') }}</span>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col vs-justify="left" vs-align="left" vs-w="12">
                    <vs-textarea label="Interne Notiz" v-model="newCommissionsData.notice"  />
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col vs-justify="left" vs-align="left" vs-w="6"class="pr-2 mt-1">
                    <vs-input label-placeholder="Einzelmenge" v-model="newCommissionsData.amount" name="amount"  data-vv-as="Einzelmenge" v-validate="'required'" @change="onChangeNewCommisionDataField('amount')"/>
                    <span class="text-danger text-sm" v-if="errors.has('amount')">{{ errors.first('amount') }}</span>
                </vs-col>
                <vs-col  vs-justify="left" vs-align="left" vs-w="6" >
                    <vs-select label="Einheit" v-model="newCommissionsData.volume_unit" class="w-full" name="volume_unit"   :disabled="newCommissionsData.commision_type == 'offer_position'" data-vv-as="volume_unit" v-validate="'required'">
                        <vs-select-item :key="index" :value="item.value" :text="item.label"
                                        v-for="(item, index) in unitOptions"/>
                    </vs-select>
                    <span class="text-danger text-sm" v-if="errors.has('volume_unit')">{{ errors.first('volume_unit') }}</span>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col vs-justify="left" vs-align="left" vs-w="6"class="pr-2">
                    <vs-input label-placeholder="Einzelpreis" v-model="newCommissionsData.unit_price"  name="unit_price"  data-vv-as="unit_price" v-validate="'required'" :disabled="newCommissionsData.commision_type == 'offer_position'" @change="onChangeNewCommisionDataField('unit_price')"/>
                    <span class="text-danger text-sm" v-if="errors.has('unit_price')">{{ errors.first('unit_price') }}</span>
                </vs-col>
                <vs-col  vs-justify="left" vs-align="left" vs-w="6" >
                    <vs-input label-placeholder="Performance in %" v-model="newCommissionsData.percent_performance"  name="percent_performance"  data-vv-as="percent_performance" :disabled="newCommissionsData.commision_type == 'other' || newCommissionsData.commision_type == 'group_position' || newCommissionsData.commision_type == 'contract_position' " v-validate="'required|between:1,100'" @change="onChangeNewCommisionDataField('percent_performance')"/>
                    <span class="text-danger text-sm" v-if="errors.has('percent_performance')">{{ errors.first('percent_performance') }}</span>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col  vs-justify="left" vs-align="left" vs-w="6"class="pr-2">
                    <vs-input label-placeholder="Anteiliger Umsatz" v-model="newCommissionsData.sales_sum"  name="sales_sum"  data-vv-as="sales_sum" v-validate="'required'" disabled="disabled"/>
                    <span class="text-danger text-sm" v-if="errors.has('sales_sum')">{{ errors.first('sales_sum') }}</span>
                </vs-col>
                <vs-col  vs-justify="left" vs-align="left" vs-w="6" >
                    <vs-input label-placeholder="Erreichte Provision" v-model="newCommissionsData.commision_sum"  name="commision_sum"  data-vv-as="commision_sum" v-validate="'required'" disabled="disabled"/>
                    <span class="text-danger text-sm" v-if="errors.has('commision_sum')">{{ errors.first('commision_sum') }}</span>
                </vs-col>
            </vs-row>

            <vs-row>

                <vs-col  vs-justify="center" vs-align="center" vs-w="12"class="mt-6">
                    <vs-button color="success"  class="mt-2 mb-2 float-right" type="filled" @click="saveCommissionToSettlement()">Speichern</vs-button>
                </vs-col>
            </vs-row>



        </vs-popup>
        <vs-popup classContent="popup-example"  title="Offene Provisionen hinzufügen" :active.sync="openCommissionPopup" v-if="openCommissionPopup">

            <vs-table
                    :data="openCommissions">
                <template slot="header">
                    <i>
                        Wähle aus den aktuell offenen Provisionen ...
                    </i>
                </template>
                <template slot="thead">
                    <vs-th>

                    </vs-th>
                    <vs-th>
                        Beschreibung + Notiz
                    </vs-th>
                    <vs-th>
                        Kunde
                    </vs-th>
                    <vs-th>
                        Menge (Performance)
                    </vs-th>
                    <vs-th>
                        Provision
                    </vs-th>
                    <vs-th>
                        Rechnungsstatus
                    </vs-th>
                    <vs-th>
                        Provisionstatus
                    </vs-th>
                    <vs-th>

                    </vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                        <vs-td>
                            <vs-checkbox v-model="selectedOpenCommissions" :vs-value="data[indextr]"></vs-checkbox>
                        </vs-td>
                        <vs-td :data="data[indextr].description">
                            {{data[indextr].description}}
                            <small v-if="data[indextr].notice">{{data[indextr].notice}}</small>
                        </vs-td>

                        <vs-td :data="data[indextr].username">
                          <span v-if="data[indextr].client">{{data[indextr].client.company_name}}</span>
                          <span v-else>--</span>
                        </vs-td>

                        <vs-td :data="data[indextr].amount">
                            {{data[indextr].amount}}  {{getVolumeUnitNameById(data[indextr].volume_unit)}} ({{data[indextr].percent_performance}}%)
                        </vs-td>

                        <vs-td :data="data[indextr].commision_sum">
                            <b>{{data[indextr].commision_sum}} €</b>
                        </vs-td>

                        <vs-td>
                            <div v-if="data[indextr].order">
                                <div v-html="data[indextr].order.order_status.label">{{data[indextr].order.order_status.label}} </div>
                            </div>
                            <div v-if="!data[indextr].order && data[indextr].commision_type == 'reference_position'">
                                <b style="color:red">Noch keine Rechnung erstellt!!</b>
                            </div>

                        </vs-td>
                        <vs-td :data="data[indextr].status.label" class="commissionStatus">
                            <span v-if="data[indextr].status_id == 1" class="draft">{{data[indextr].status.label}}</span>
                            <span v-if="data[indextr].status_id == 2" class="readybyEmployee">{{data[indextr].status.label}}</span>
                            <span v-if="data[indextr].status_id == 3" class="stop">{{data[indextr].status.label}}</span>
                            <span v-if="data[indextr].status_id == 4" class="readybyChef">{{data[indextr].status.label}}</span>

                        </vs-td>
                        <vs-td class="actions">

                                <feather-icon icon="MessageCircleIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" @click="openMessagesPopup(data[indextr])"   />
                                <feather-icon icon="ArchiveIcon"  class="inline-block mr-2" :class="{ 'active ArchiveIcon':  data[indextr].status_id == '1' }" svgClasses="w-4 h-4" @click="changeState(data[indextr], 1)" title="Status: Entwurf"   />
                                <feather-icon icon="ThumbsUpIcon"  class="inline-block mr-2" :class="{ 'active ThumbsUpIcon':  data[indextr].status_id == '2' }" svgClasses="w-4 h-4" @click="changeState(data[indextr], 2)"  title="Status: Freigegeben von Mitarbeiter"    />
                                <feather-icon icon="PauseIcon"  class="inline-block mr-2" :class="{ 'active PauseIcon':  data[indextr].status_id == '3' }" svgClasses="w-4 h-4" @click="changeState(data[indextr], 3)"  title="Status: In Klärung"    />
                                <feather-icon icon="LoaderIcon"  class="inline-block mr-2" :class="{ 'active LoaderIcon':  data[indextr].status_id == '4' }" svgClasses="w-4 h-4" @click="changeState(data[indextr], 4)"  title="Status: Freigegeben von Chef"    />

                         </vs-td>
                    </vs-tr>
                </template>
            </vs-table>

            <vs-button color="success"  class="mt-2 mb-2 float-right" type="filled" @click="saveOpenCommissionToSettlement()">Hinzufügen</vs-button>

        </vs-popup>
        <vs-popup classContent="popup-example"  title="Kommunikation" :active.sync="messagesPopup" v-if="messagesPopup">

            Hier wird die Kommunikation zu der Provision stattfinden



        </vs-popup>


    </div>
</template>



<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api/index";
    import moment from 'moment';
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import QueryHelper from "../../../mixins/helper/query.helper";
    import * as qs from "qs";
    import PriceHelper from "../../../mixins/helper/price.helper";
    import vSelect from 'vue-select'
    import staticOptions from "../../../mixins/static/options";
    import MgSelect from "../../../components/mg-select/MgSelect";
    import {mapGetters} from "vuex";
    import StatisticsHistory from "@/views/human-resources/commision/StatisticsHistory";

    export default {
        name: "CommisionItems",
        components: {
            StatisticsHistory,
            flatPickr,
            VueBootstrap4Table,
            'v-select': vSelect,
            MgSelect
        },
        data() {
            return {
                showStatisticsHistory: false,

                userListActive: true,
                userSearchTerm: null,
                users: [],
                searchFilter: null,
                filteredUsers: [],
                val: false,
                selectedUser: null,
                stampssheet: [],


                year: null,
                month: null,
                selectedPositionAction: null,
                PositionActions: [{"id":1,"name":"Status auf ReadybyEmployee setzen"},{"id":2,"name":"Status auf ReadybySupervisor setzen"},{"id":3,"name":"Positionen von dieser Abrechnung entfernen"}],



                dataSettlement: null,
                dataSettlementItems: null,
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/invoices/add',
                        color: 'success'
                    }
                ],
                columns: [
                    {
                        label: "Beschreibung",
                        name: "description",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Kunde",
                        name: "client.company_name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },


                    {
                        label: "Menge",
                        name: "amount",
                        slot_name:"amount",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },


                    {
                        label: "Perf.",
                        name: "percent_performance",
                        slot_name:"percent_performance",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        width: '3%'
                    },

                    {
                        label: "E-preis",
                        name: "unit_price",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '3%'
                    },
                    {
                        label: "Summe",
                        name: "sales_sum",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '5%'
                    },
                    {
                        label: "Provision",
                        name: "commision_sum",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '5%'
                    },
                    {
                        label: "Anhänge",
                        width: '10%',
                        name: "attachments",
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                    },
                    {
                        label: "Status",
                        name: "status",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        width: '240px',
                        slot_name: "status",
    /** filter: {
                            type: 'select',
                            options: this.statusOptions,
                            mode: 'multi',
                            value: [{"value": 1, "label": "Entwurf"}, {"value": 2, "label": "Freigegeben von Mitarbeiter\n"}]
                        },**/
                        initial_sort: true,
                        initial_sort_order: 'asc',
                    },
                    {
                        label: "Notiz",
                        name: "notice",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        width: '12%'
                    },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column',
                        showCol: true,
                        width: '140px',

                    }],
                config: {
                    checkbox_rows: true,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true,
                    global_search: {
                        visibility: false
                    }
                },
                queryParams: {
                    sort: [{
                        caseSensitive: true,
                        name: "status",
                        order: "asc"
                    }],
                    filters: [],
                    global_search: "",
                    per_page: 20,
                    page: 1,
                },
                total_rows: 0,
                status: [],


                activePayedPrompt: false,
                payDatePromptOrderId: null,

                commissionPopup: false,
                emptyCommissionsData:{
                    "workspace_id": null,
                    "commision_type": "other",
                    "user_id": null,
                    "description": "",
                    "amount": 1,
                    "volume_unit": 1,
                    "unit_price": 0,
                    "percent_performance": 100,
                    "sales_sum": 0,
                    "commision_sum": 0,
                    "commision_id": null,
                    "accounting_id": null,
                    "order_id": null,
                    "order_item_id": null,
                    "contract_id": null,
                    "article_id": null,
                    "client_id": null,
                    "offer_id": null,
                    "offer_item_id": null,
                    "notice": "",
                    "status_id": 1,
                    "created_by_user_id": null,
                },

                newCommissionsData:[],
                commisionTypes: ["group_position","contract_position","offer_position","other"],
                clients: [],
                offers: [],
                offerItems: [],
                selectedOfferItem: [],
                contracts: [],

                openCommissionPopup: false,
                openCommissions: null,
                selectedOpenCommissions:[],
                messagesPopup:false,
                edit_status_to_billed_commission_settlement: false,
            }

        },
        async created() {
            let date = new Date();
                this.month = this.$route.params.month ||  moment().format('MM');
                this.year = this.$route.params.year || moment().format('YYYY');

            this.$vs.loading();
            this.emptyCommissionsData.workspace_id = this.workspace.id
            await this.checkIfAdmin()
            await this.fetchUser()

            if(this.$route.params.userId) {
                this.fetchData(true, this.$route.params.userId)
            }
            this.$vs.loading.close()

        },
        computed: {
            ...mapGetters(['userId','workspace']),
            unitOptions() {
                return staticOptions.units;
            },
            requestParams() {
                let queryParams = QueryHelper.parseRequestParams(this.queryParams);

                return queryParams;
            },
            paidStatusId() {
                const status = this.status.find(item => item.is_default_paid)
                if (!status) {
                    return null;
                }
                return status.id;
            },
            pageProvSum() {
                if(!this.dataSettlementItems) {
                    return PriceHelper.formatCurrency(0);
                }
                const sum = this.dataSettlementItems.reduce((acc, item) => {return acc + item.commision_sum;}, 0);
                return PriceHelper.formatCurrency(sum);
            }, pageSum() {
                if(!this.dataSettlementItems) {
                    return PriceHelper.formatCurrency(0);
                }
                const sum = this.dataSettlementItems.reduce((acc, item) => {return acc + item.sales_sum;}, 0);
                return PriceHelper.formatCurrency(sum);
            }
        },
        methods: {
            checkIfAdmin() {
               return  ApiService.post(`auth/functionPermission`, {permissionKey: "edit_status_to_billed_commission_settlement"} )
                    .then(response => {
                        if (response.data.status == "success") {
                            this.edit_status_to_billed_commission_settlement = true;

                        } else {
                            this.edit_status_to_billed_commission_settlement = false;
                        }
                    });
            },
            changeSelectedYearOrDate (year,month) {
                this.month = month;
                this.year = year;
                this.$router.push('/manage-commissions/' + this.selectedUser.id + '/' + this.year + '/' + this.month);
                this.fetchData(false, this.$route.params.userId)
            },
            checkPermission() {
                return ApiService.get('users').then(response => {
                    this.users = response.data.result;
                    this.filteredUsers  = response.data.result;
                }).catch(response => {
                })
            },
            fetchUser() {

                if(this.edit_status_to_billed_commission_settlement === false) {
                    let queryParams = {
                        filter: [{
                            field: 'api_key',
                            value: localStorage.getItem('apiKey_'+localStorage.getItem('current_workspace')),
                            expression: 'exact'
                        }]
                    };
                    return  ApiService.get('users', {
                        params: queryParams,
                        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                    }).then(response => {
                        this.users = response.data.result;
                        this.filteredUsers  = response.data.result;
                    })

                } else {
                    return ApiService.get('users').then(response => {
                        this.users = response.data.result;
                        this.filteredUsers  = response.data.result;
                    }).catch(response => {
                    })
                }


            },
            AddCommissionPopup() {
                this.newCommissionsData =  JSON.parse(JSON.stringify(this.emptyCommissionsData));
                var fetchClients = this.fetchClients();
                this.fillIndependentCommissionData();
                this.commissionPopup = true;
            },
            fillIndependentCommissionData() {
                this.newCommissionsData.created_by_user_id = this.userId;
                this.newCommissionsData.user_id = this.$route.params.userId;
            },
            fetchClients(){
                ApiService.get(`clients`)
                    .then(response => {
                        if (response.status == 200) {
                            this.clients = response.data.result;
                        } else {
                            this.$vs.notify({
                                title: 'Fehler beim Kunden laden',
                                text: '',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        }
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehler beim Kunden laden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    });


            },
            AddOpenCommissionPopup() {
                this.fetchOpenCommissionItems();
                this.openCommissionPopup = true;
            },
            filterUsers() {
                const initalUsers = JSON.parse(JSON.stringify(this.users));
                if(!this.searchFilter || this.searchFilter.length <= 1) {
                    this.filteredUsers = initalUsers
                }
                const value = this.searchFilter.toLowerCase();

                this.filteredUsers = initalUsers.filter((item) => {
                    return ((item.detail.first_name.toLowerCase().indexOf(value) > -1) || (item.detail.last_name.toLowerCase().indexOf(value) > -1));
                });

            },
            selectUser(user) {

                this.selectedUser = user;
                this.$router.push('/manage-commissions/' + this.selectedUser.id + '/' + this.year + '/' + this.month);
                this.userListActive = false;
                this.fetchData(false, this.$route.params.userId)
            },

            fetchData(initial = false, userId) {
                if (!userId) {
                    return;
                }

                if(this.selectedUser == undefined || this.selectedUser == null) {
                    const users = JSON.parse(JSON.stringify(this.users));
                    let user = users.find(food => food.id == userId);

                    if (user !== null && user !== undefined) {
                        this.selectedUser = user;
                    } else {
                       return this.$vs.notify({
                            title: 'Kein User gefunden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
                this.$vs.loading();
                this.fetchSettlement();
                if (!initial && this.dataSettlement) {
                    return this.fetchSettlementItems();
                }
                ApiService.get('commision_status').then(response => {
                    this.status = response.data.result;
                    this.setStatusOptions();
                    //this.fetchOrders();
                })

                this.userListActive = false;
            },

            fetchSettlement() {
                ApiService.get(`commision_settlement/${this.selectedUser.id}?wage_period_year=${this.year}&wage_period_month=${this.month}`)
                    .then(response => {
                    if (response.error == undefined){
                        this.dataSettlement = response.data.result;
                        this.fetchSettlementItems();
                        this.$vs.loading.close()
                    } else {
                        this.dataSettlement = response.data.error.description;
                        this.$vs.loading.close()
                    }
                })
                    .catch((error) => {
                        this.$vs.loading.close();
                        this.dataSettlement = null;
                        this.dataSettlementItems = null;

                        this.$vs.notify({
                            title: 'Kein Provisionsabrechnung gefunden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
                return
            },

            fetchSettlementItems() {
                ApiService.get(`commision_item/${this.selectedUser.id}?wage_period_year=${this.year}&wage_period_month=${this.month}`).then(response => {
                    if (response.error == undefined){
                        this.dataSettlementItems = response.data.result;
                        this.total_rows = response.data.total;
                    } else {
                        this.dataSettlementItems = response.data.error.description;
                    }
                    this.$vs.loading.close()
                })
                return
            },

            fetchOpenCommissionItems() {
                this.openCommissions = [];
                ApiService.get(`commision_item/${this.selectedUser.id}?commision_id=NULL`).then(response => {
                    if (response.error == undefined){
                        this.openCommissions = response.data.result;
                    } else {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: response.data.error.description,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                    this.$vs.loading.close()
                })
                return
            },


            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData(false, this.$route.params.userId)
            },
            orderStatusFormatter(value) {
                const status = this.getStatusById(value);

                if (!status) {
                    return value;
                }

                return status.label;
            },

            getStatusById(id) {
                id = parseInt(id);
                return this.status.find(item => item.id === id);
            },
            showEditButton(id) {
                const status = this.getStatusById(id);

                if (!status) {
                    return false;
                }

                return status.is_draft === 1;
            },
            formatDateTime(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            setStatusOptions() {
                let options = [];

                this.status.forEach((status) => {
                    options.push({
                        value: status.id,
                        label: status.label
                    })
                });

                this.columns[0].filter.options = options;
            },

            getParentStatus(itemStatusId) {
                const itemStatus = this.status.find(item => item.id === parseInt(itemStatusId))

                if(!itemStatus.parent_id) {
                    return null;
                }

                const status = this.status.find(item => item.id === itemStatus.parent_id)

                if (!status) {
                    return null;
                }

                return status;
            },
            getVolumeUnitNameById(id) {
                if(typeof id == 'undefined' || id == null) {
                    return "X";
                }
                const units = staticOptions.units.find(item => item.value === id);
                if(typeof units == 'undefined') {
                    return "X";
                }
                return units.label

            },
            onClickAllPositionAction() {
                // ToDO:

            },
            deleteCommision(item) {


                    this.$vs.notify({
                        title:'Möchtest du die Provision wirklich aus der Datenbank löschen?',
                        text:'Dann klicke hier!!',
                        time: 5000,
                        color:'dark',
                        position:'bottom-center',
                        fixed:true,
                        click:()=>{
                            // Secondary function

                            ApiService.delete(`commision_item/${item.id}`)
                                .then((response) => {
                                    if(response.data.status === 'success') {
                                        this.fetchSettlementItems();
                                        return this.$vs.notify({
                                            title: 'Erfolgreich',
                                            text: 'Der Provisionen wurde erfolgreich gelöscht',
                                            iconPack: 'feather',
                                            icon: 'icon-alert-circle',
                                            color: 'success'
                                        })


                                    }

                                    this.$vs.notify({
                                        title: 'Fehlgeschlagen',
                                        text: 'Die Provisionen konnte nicht gelöscht werden.',
                                        iconPack: 'feather',
                                        icon: 'icon-alert-circle',
                                        color: 'danger'
                                    })
                                })
                                .catch((error) => {
                                    this.$vs.notify({
                                        title: 'Fehlgeschlagen',
                                        text: 'Die Provisionen konnte nicht gelöscht werden',
                                        iconPack: 'feather',
                                        icon: 'icon-alert-circle',
                                        color: 'danger'
                                    })
                                })

                        },
                    })



            },
            removeFromSettlement(item){

                item.commision_id = null;
                ApiService.put(`commision_item/${item.id}`, item)
                    .then((response) => {
                        if(response.data.status === 'success') {
                            this.fetchSettlementItems();
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Der Provisionen wurde erfolgreich geändert',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            })


                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Die Provisionen konnte nicht geändert werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Die Provisionen konnte nicht geändert werden',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
            },
            saveCommissionToSettlement(){
                this.$validator.validate().then(valid => {
                        if (valid) {

                            this.newCommissionsData["commision_id"] = this.dataSettlement.id;
                            this.newCommissionsData["user_id"] = this.$route.params.userId;
                            this.newCommissionsData["created_by_user_id"] = this.$route.params.userId;
                            this.newCommissionsData["unit_price"] = PriceHelper.parseComma(this.newCommissionsData["unit_price"]);
                            this.newCommissionsData["amount"] = PriceHelper.parseComma(this.newCommissionsData["amount"]);
                            this.newCommissionsData["sales_sum"] = PriceHelper.parseComma(this.newCommissionsData["sales_sum"]);
                            this.newCommissionsData["commision_sum"] = PriceHelper.parseComma(this.newCommissionsData["commision_sum"]);

                            ApiService.post(`commision_item`, this.newCommissionsData)
                                .then((response) => {
                                    if(response.data.status === 'success') {
                                        this.fetchSettlementItems();
                                        this.commissionPopup = false;
                                        return this.$vs.notify({
                                            title: 'Erfolgreich',
                                            text: 'Der Provisionen wurde erfolgreich geändert',
                                            iconPack: 'feather',
                                            icon: 'icon-alert-circle',
                                            color: 'success'
                                        })


                                    }

                                    this.$vs.notify({
                                        title: 'Fehlgeschlagen',
                                        text: 'Die Provisionen konnte nicht geändert werden.',
                                        iconPack: 'feather',
                                        icon: 'icon-alert-circle',
                                        color: 'danger'
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                    this.$vs.notify({
                                        title: 'Fehlgeschlagen',
                                        text: 'Die Provisionen konnte nicht geändert werden ' + error.response.data.message,
                                        iconPack: 'feather',
                                        icon: 'icon-alert-circle',
                                        color: 'danger'
                                    })
                                })


                        } else {
                            this.$vs.notify({
                                title: 'Fehlgeschlagen',
                                text: this.$validator.errors.all(),
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        }
                });
            },
            saveOpenCommissionToSettlement(){
                for (var i = 0; i < this.selectedOpenCommissions.length; i++) {
                    if ( this.selectedOpenCommissions[i].commision_id === null || this.selectedOpenCommissions[i].commision_id === undefined ) {
                        this.selectedOpenCommissions[i].commision_id = this.dataSettlement.id
                    } else {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Bei der Zuweisung der offenen Provisionen ist etwas schief gelaufen',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }

                }

                ApiService.put('commision_item/batch_update', this.selectedOpenCommissions)
                    .then((response) => {
                        if(response.data.status === 'success') {
                            this.fetchSettlementItems();
                            this.openCommissionPopup = false;
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Der Provisionen wurde erfolgreich hinzugefügt',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            })


                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Die Provisionen konnte nicht hinzugefügt werden werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Die Provisionen konnte nicht hinzugefügt werden werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })


            },
            setallCommisionStatus() {
                for (var i = 0; i < this.dataSettlementItems.length; i++) {
                    if ( this.dataSettlementItems[i].status_id != 3 ) {
                        this.dataSettlementItems[i].status_id = 5
                    } else {

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Es dürfen keine - in Klärung - Positionen in der Abrechnung sein',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                        return false;
                    }

                }
                ApiService.put('commision_item/batch_update', this.dataSettlementItems)
                    .then((response) => {
                        if(response.data.status === 'success') {
                            this.fetchSettlementItems();
                            return true;
                        }
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Es ist etwas schief gelaufen',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                        return false;
                    })
            },
            onClickSave(stateId){
                let payload = this.dataSettlement;
                this.selectedOpenCommissions

                delete payload.updated_at;
                delete payload.created_at;


                if(stateId == 3) {
                    var statusChange = this.setallCommisionStatus(stateId);
                    if(statusChange == false) {
                        return
                    }
                }

                payload.status = stateId;
                payload.commision_sum =   this.dataSettlementItems.reduce((acc, item) => {return acc + item.commision_sum;}, 0);



                ApiService.put(`commision_settlement/${payload.id}`, payload)
                    .then((response) => {
                        if(response.data.status === 'success') {
                            this.fetchData(false, this.$route.params.userId)
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Erfolgreich gespeichert',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            })
                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Konnte nicht bearbeitet werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Konnte nicht bearbeitet werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })


            },
            createSettlement(userId,month,year){
                if(!userId && !month && !year) {
                    this.$vs.notify({
                        title: 'Abrechnung konnte nicht erstellt werden',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                    return
                }
                let settlement = {
                    "user_id":userId,
                    "status":1,
                    "wage_period_year":  parseInt(year),
                    "wage_period_month":month
                    }

                ApiService.post('commision_settlement', settlement).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Provisionsabrechnung wurde erfolgreich erstellt.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });

                    this.fetchData(true, this.$route.params.userId)

                }).catch((error) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Fehler',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });

            },
            changeState(item,statusId) {
                item.status_id = statusId
                this.$vs.loading();


                ApiService.put(`commision_item/${item.id}`, item).then(() => {
                    if(this.openCommissionPopup == true) {
                        this.fetchOpenCommissionItems();
                    } else {
                        this.fetchSettlementItems();
                    }
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Änderung wurde erfolgreich gespeichert.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });

                }).catch((error) => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: 'Fehler',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            },
            openMessagesPopup(item) {
                this.messagesPopup = true
            },
            toggleUserList() {
                if(this.selectedUser) {
                    this.userListActive = !this.userListActive;
                }

            },
            fetchOffers() {
                let queryParams = {
                    filter: [
                        {
                            field: 'type.invoice_type',
                            expression: 'exact',
                            value: 'offer'
                        },
                        {
                            expression: 'group',
                            filter: [
                                {
                                    field: 'status',
                                    expression: 'exact',
                                    value: '16',
                                    operator: 'or',
                                },
                                {
                                    field: 'status',
                                    expression: 'exact',
                                    value: '18',
                                    operator: 'or',
                                },
                                {
                                  field: 'status',
                                  expression: 'exact',
                                  value: '19',
                                  operator: 'or',
                                }
                            ]
                        }
                    ]
                };

                ApiService.get('orders', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.offers = response.data.result;
                    return true;
                })
            },
            fetchContracts() {
                let queryParams = {
                    filter: [
                        {
                            field: 'status',
                            expression: 'exact',
                            value: '1'
                        }
                    ]
                };

                ApiService.get('contracts', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.contracts = response.data.result;
                    return true;
                })
            },
            changeCommisionType() {
                let commision_type =  JSON.parse(JSON.stringify(this.newCommissionsData.commision_type));
                this.newCommissionsData =  JSON.parse(JSON.stringify(this.emptyCommissionsData));
                this.newCommissionsData.commision_type =  commision_type;
                this.fillIndependentCommissionData();

                if(this.newCommissionsData.commision_type === 'offer_position') {
                    this.fetchOffers();
                } else if(this.newCommissionsData.commision_type === 'contract_position') {
                    this.fetchContracts();
                }
            },
            onOfferChange(item){
                this.newCommissionsData.client_id = item.client_id
                this.offerItems = [];
                this.offerItems = item.items;
            },
            onOfferItemChange(item) {
                this.newCommissionsData.amount = item.amount;
                this.newCommissionsData.volume_unit = item.volume_unit;
                this.newCommissionsData.unit_price = item.unit_price_netto;
                this.newCommissionsData.article_id = item.article_assignment_id;
                if(item.short_description) {
                    this.newCommissionsData.description = item.product_name + "<small>" + item.short_description + "</small>";
                } else {
                    this.newCommissionsData.description = item.product_name
                }

                this.selectedOfferItem = JSON.parse(JSON.stringify(item));

                this.onChangeNewCommisionDataField("percent_performance");



            },
            onContractChange(item) {
                this.newCommissionsData.description = item.type.name
            },

            onChangeNewCommisionDataField(input_name){
                if(input_name === 'percent_performance') {

                    if(this.newCommissionsData.percent_performance > 100 || this.newCommissionsData.percent_performance == null || this.newCommissionsData.percent_performance === 0 || isNaN(parseFloat(this.newCommissionsData.percent_performance.toString().replace(',', '.')))) {
                        this.newCommissionsData.percent_performance = 100
                    }
                    if(this.newCommissionsData.commision_type == "offer_position") {
                        this.newCommissionsData.amount = parseFloat(((this.newCommissionsData.percent_performance / 100) * this.selectedOfferItem.amount).toFixed(5))
                    }
                    this.newCommissionsData.sales_sum = (PriceHelper.parseComma(this.newCommissionsData.unit_price) * PriceHelper.parseComma(this.newCommissionsData.amount)).toFixed(2)
                }
                if(input_name === 'amount') {
                    if (this.newCommissionsData.amount > this.selectedOfferItem.amount) {
                        this.newCommissionsData.amount = this.selectedOfferItem.amount
                    }
                    this.newCommissionsData.sales_sum = (PriceHelper.parseComma(this.newCommissionsData.unit_price) * PriceHelper.parseComma(this.newCommissionsData.amount)).toFixed(2)
                    if (this.newCommissionsData.commision_type == "offer_position") {
                        this.newCommissionsData.percent_performance = ((PriceHelper.parseComma(this.newCommissionsData.amount) / PriceHelper.parseComma(this.selectedOfferItem.amount)) * 100).toFixed(5)
                    }
                }

                if(input_name === 'unit_price') {
                    this.newCommissionsData.sales_sum = (PriceHelper.parseComma(this.newCommissionsData.unit_price) * PriceHelper.parseComma(this.newCommissionsData.amount)).toFixed(2)
                }

                this.newCommissionsData.commision_sum = (this.newCommissionsData.sales_sum * this.selectedUser.employee_settings.provision) / 100;

            },
            parsePercent(percentage, decimal = 0) {
                return parseFloat(percentage).toFixed(decimal);

            },
            customOfferLabel(option) {
                return `${option.invoice_number} - ${option.title} - ${option.order_date}`;
            },
            customOfferItemLabel(option) {
                return `${option.product_name} - ${option.title} - ${option.short_description}`;
            },
            customClientLabel(option) {
              if (!option.customer_number || !option.company_name) {
                return "--";
              }
                return `${option.customer_number} - ${option.company_name}`;
            },
            customContractLabel(option) {

              if (!option.type || !option.type.name || !option.client.company_name || !option.client.customer_number) {
                return "--";
              }

                return `${option.type.name} - ${option.client.company_name} - ${option.client.customer_number}`;
            },

        },
        mounted() {
        },
        watch: {
            $route(to, from) {
                this.fetchData(false, to.params.userId)
            }
        }
    }
</script>

<style lang="scss">
    span.badge-warning {
        background: #ec7b24;
        color: #fff;
        padding: 5px 10px;
        border-radius: 13px;
        font-size: 10px;
    }
    .commissionsStatus {
        font-size: 40px;
        font-weight: 500;
        text-align: right;
        &.draft {
            color: grey;

        }
        &.ready {
            color: #2193ca;


        }
        &.success {
            color: #239e23;

        }
    }
    .Settlement {
        .notice{
            margin-top: 30px;
        }
    }
    .commissionsarea {
        margin-top: 30px;

        .result-list {
            margin-top: 15px;
            text-align: right;
            span {
                font-size: 25px;
                font-weight: bold;
                display: block;
            }
        }
        .con-select {
            width: 300px;
        }
    }

    .commissionsPositions {
        margin-top: 60px;
    }
    .con-vs-popup .vs-popup {
        width: auto !important;
    }

    .commissionStatus .draft {
        color: #8e8e8e;

    }

    .commissionStatus .readybyEmployee {
        color: #a5a412;
    }

    .commissionStatus .stop {
        color: red;
    }

    .commissionStatus .readybyChef {
        color: #23a000;
    }
    .actions .ArchiveIcon.active {
        color: #d8d8d8;
    }

    .actions .PauseIcon.active {
        color: red;
    }
    .actions .MessageCircleIcon.active {
        color: #868686;
    }
    .actions .ThumbsUpIcon.active {
        color: #bade74;
    }
    .actions .LoaderIcon.active {
        color: #09980b;
    }
    .column-actions-group .next-run i.vs-icon {
        font-size: 2rem!important;
    }
    .vs-input.width-100 {
        width: 100%;
    }
    .link.AddCommissionPopup {
        position: relative;
        margin-top: 20px;
        display: block;
    }
    .container-row.actions .feather-icon {
        cursor: pointer;
    }

    .toggleUserList {
        position: absolute;
        top: -19px;
        right: 15px;
        cursor: pointer;
    }

    .toggleUserList:hover {
        color: #e24733;
    }

    .toggleUserList.outside {
        position: absolute;
        top: inherit;
        right: inherit;
        margin-top: 3px;
        margin-left: -9px;
    }


</style>