<template>
  <div id="licence-statistics">
    <div v-if="isLoading" class="text-center">

      <p>Statistiken werden geladen...</p>
    </div>
    <div v-else>

      <!-- Jahr-Selector und Toggle -->
      <div class="vx-row mb-base align-center">
        <div class="vx-col w-full lg:w-1/3 mb-base text-bold">
          <b>Filtern:</b>
        </div>
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <vs-select
              :disabled="showAllYears"
              v-model="selectedYear"
              placeholder="Jahr auswählen"
              class="w-full"
          >
            <vs-select-item
                v-for="(year, index) in years"
                :key="index"
                :value="year"
                :text="year"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full lg:w-1/3 mb-base flex justify-center">
          <vs-switch
              v-model="showAllYears"
              color="primary"
              :value="showAllYears"
          ></vs-switch>
          <b class="ml-3">Alle Jahre anzeigen</b>

        </div>
      </div>

      <div class="vx-row mb-base">

        <!-- Verkaufte Lizenzen pro Quartal -->
        <div class="vx-col w-full lg:w-1/3 mb-base">
          <vs-card class="h-full d-block">
            <h3>Verkaufte Lizenzen pro Quartal</h3>
            <vue-apex-charts type="bar" height="350" :options="licenseQuarterChart.options"
                             :series="licenseQuarterChart.series"/>
          </vs-card>
        </div>

        <!-- Meistverkaufte Produkte -->
        <div class="vx-col w-full lg:w-2/3 mb-base">
          <vs-card class="h-full d-block">
            <h3>Meistverkaufte Produkte</h3>
            <vue-apex-charts type="pie" height="350" :options="productChart.options" :series="productChart.series"/>
          </vs-card>
        </div>

        <!-- Umsatz pro Produkt -->
        <div class="vx-col w-full lg:w-3/3 mb-base">
          <vs-card class="h-full">
            <h3>Umsatz je Produkt</h3>
            <vue-apex-charts type="line" height="350" :options="revenueChart.options" :series="revenueChart.series"/>

          </vs-card>
        </div>



        <!-- Miete vs. Kauf -->
        <div class="vx-row mb-base">
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <vs-card >
              <h3>Miete vs. Kauf</h3>
              <vue-apex-charts
                  type="pie"
                  height="350"
                  :options="licenseTypeChart.options"
                  :series="licenseTypeChart.series"
              />
            </vs-card>
          </div>

          <!-- Editionen -->
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <vs-card >
              <h3>Welche Editionen?</h3>
              <vue-apex-charts
                  type="bar"
                  height="350"
                  :options="editionChart.options"
                  :series="editionChart.series"
              />
            </vs-card>
          </div>


        <!-- Hauptlizenz vs. Addons -->

          <div class="vx-col w-full lg:w-1/2 mb-base">
            <vs-card >
             <h3>Hauptlizenz vs. Addons (Umsatz)</h3>
              <vue-apex-charts
                  type="treemap"
                  height="350"
                  :options="coreAddonChart.options"
                  :series="coreAddonChart.series"
              />
            </vs-card>
          </div>
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <vs-card >
              <h3>Welche Addons sind aktiv lizenziert?</h3>asd
              <vs-table :data="addonUsage"/>

              <template slot="thead">
                <vs-th>Addon Name</vs-th>
                <vs-th>Anzahl</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(addon, indextr) in addonUsage">
                  <vs-td>
                    {{ addon.addon_name }}
                  </vs-td>

                  <vs-td>
                    {{ addon.count }}
                  </vs-td>

                </vs-tr>
              </template>

            </vs-card>
          </div>
        </div>

        <div class="vx-col lg:w-1/2  w-full">
          <vs-card >
            <h3>Stornierte/Inaktive Lizenzen</h3>
            <vs-table :data="canceledLicenses" stripe>
              <template #default="{ data }">
                <vs-tr v-for="(item, index) in data" :key="index">
                  <vs-td>{{ item.client_name }}</vs-td>
                  <vs-td>{{ item.product_name }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-card>
        </div>

      </div>

      <!-- Weitere Statistiken -->
      <div class="vx-row mb-base">
        <!-- Aktive Lizenzen -->
        <div class="vx-col w-full lg:w-1/4 mb-base">
          <vx-card title="Aktive Lizenzen" class="h-full">
            <h2 class="text-center">{{ activeLicenses }}</h2>
          </vx-card>
        </div>

        <!-- Lizenzen mit Freigabe -->
        <div class="vx-col w-full lg:w-1/4 mb-base">
          <vx-card title="Lizenzen mit Marketingfreigabe" class="h-full">
            <h2 class="text-center">{{ licensesWithApproval }}</h2>
          </vx-card>
        </div>

        <!-- Gesamtumsatz -->
        <div class="vx-col w-full lg:w-1/4 mb-base">
          <vx-card title="Gesamtumsatz" class="h-full">
            <h2 class="text-center">{{ formatCurrency(totalRevenue) }}</h2>
          </vx-card>
        </div>

        <!-- Partneranzahl -->
        <div class="vx-col w-full lg:w-1/4 mb-base">
          <vx-card title="Aktive Partner mit Lizenz" class="h-full">
            <h2 class="text-center">{{ activePartners }}</h2>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ApiService from "@/api";

export default {
  components: {VueApexCharts},
  data() {
    return {
      isLoading: true,
      years: [2022, 2023, 2024, 2025, 2026],
      selectedYear: 2025,
      showAllYears: false,
      licenseTypeChart: { options: { labels: ["Miete", "Kauf"] }, series: [] },
      editionChart: { options: { xaxis: { categories: [] } }, series: [] },
      coreAddonChart: { options: {  chart: { type: "treemap" }, tooltip: {
            y: {
              formatter: (value) => `${value.toLocaleString()} €`,
            },
          }, }, series: [] },
      canceledLicenses: [],
      addonUsage: [],
      licenseQuarterChart: {
        options: {
          chart: {toolbar: {show: false}},
          xaxis: {categories: []},
        },
        series: [],
      },
      productChart: {
        options: {
          labels: [],
          chart: {toolbar: {show: true}},
        },
        series: [],
      },
      revenueChart: {
        options: {
          chart: {toolbar: {show: false}},
          xaxis: {categories: []},
        },
        series: [],
      },
      activeLicenses: 0,
      licensesWithApproval: 0,
      totalRevenue: 0,
      activePartners: 0,
    };
  },
  mounted() {
    this.fetchStatistics();
  },
  methods: {
    async fetchStatistics() {
      this.isLoading = true;
      try {
        const params = this.showAllYears ? {} : {year: this.selectedYear};
        const response = await ApiService.get("b2b-sellers/licence/statistics", {params});
        const stats = response.data.data;


        // Verkaufte Lizenzen pro Quartal
        this.licenseQuarterChart.series = [
          {
            name: "Verkaufte Lizenzen",
            data: Object.values(stats.licensesByQuarter),
          },
        ];
        this.licenseQuarterChart.options.xaxis.categories = Object.keys(stats.licensesByQuarter);

        // Meistverkaufte Produkte
        this.productChart.series = stats.productSales.map((item) => item.sales);
        this.productChart.options.labels = stats.productSales.map((item) => item.product_name);

        // Umsatz pro Produkt
        this.revenueChart.series = [
          {
            name: "Umsatz",
            data: stats.revenuePerProduct.map((item) => item.revenue),
          },
        ];
        this.revenueChart.options.xaxis.categories = stats.revenuePerProduct.map((item) => item.product_name);


        this.licenseTypeChart.series = stats.licenseTypeStats.map((item) => item.count);

        this.editionChart.series = [{ name: "Stückzahl", data: stats.editionStats.map((item) => item.count) }];
        this.editionChart.options.xaxis.categories = stats.editionStats.map((item) => item.edition);

        this.coreAddonChart.series = [
          {
            data: [
              { x: "Hauptlizenz", y: stats.coreVsAddonRevenue.core_revenue },
              { x: "Addons", y: stats.coreVsAddonRevenue.addon_revenue },
            ],
          },
        ];

        this.addonUsage = stats.addonUsage;

        // Weitere Statistiken
        this.activeLicenses = stats.activeLicenses;
        this.licensesWithApproval = stats.licensesWithApproval;
        this.totalRevenue = stats.totalRevenue;
        this.activePartners = stats.activePartners;
        this.canceledLicenses = stats.canceledLicenses;

        this.isLoading = false;
      } catch (error) {
        console.error("Fehler beim Laden der Statistiken:", error);
        this.isLoading = false;
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    },
  },
  watch: {
    selectedYear(newYear) {
      if (!this.showAllYears) {
        this.fetchStatistics();
      }
    },
    showAllYears(newValue) {
      this.fetchStatistics();
    },
  },
};
</script>

<style scoped>
#licence-statistics {
  .vx-card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  h2 {
    font-size: 2rem;
    margin: 0;
  }

  .align-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


}
</style>
