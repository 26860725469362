<template>
    <vs-row>
        <vs-col vs-justify="center" vs-align="center" vs-w="7">
            <vs-row>
                <vx-card class="mr-2">
                    <div class="year-month-selection">
                        <div class="menu-bar mb-3">
                            <div class="menu-bar--list">
                                <template v-for="year in years">
                                    <div class="menu-bar--list-item"
                                         :class="{'active': year === currentYear && !changedDateRange}"
                                         @click="onChangeYear(year)">
                                        {{ year }}
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="menu-bar mb-8 shadow months" v-show="months && months.length > 0">
                            <div class="menu-bar--list stretch">
                                <template v-for="month in months">
                                    <div class="menu-bar--list-item"
                                         :class="{'active': month.month === currentMonth && !changedDateRange}"
                                         @click.prevent="onChangeMonth(month)">
                                        {{ month.name }}
                                    </div>
                                </template>
                            </div>
                        </div>

                    </div>


                    <div class="filter-container">

                        <vs-row>
                            <vs-col vs-align="center" vs-w="4">
                                <div class="form-group has-clear-right">
                                    <input type="text" placeholder="Suchen..." v-model="searchInput"
                                           class="form-control mr-2"
                                           v-on:keyup.enter="fetchData">
                                    <vs-button color="primary" type="border" icon="search"
                                               @click="fetchData"></vs-button>
                                </div>
                            </vs-col>
                            <vs-col vs-align="center" vs-w="4">
                                <div class="form-group has-clear-right" title="Überweisungsdatum">
                                    <date-range-picker
                                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                            :timePicker="false"
                                            :timePicker24Hour="false"
                                            :showWeekNumbers="false"
                                            :showDropdowns="false"
                                            :autoApply="true"
                                            v-model="dateRange"
                                            @update="updateValues"
                                            @toggle="checkOpen"
                                    >
                                        <template v-slot:input="picker" style="min-width: 350px;">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                    </date-range-picker>
                                    <vs-button color="primary" type="border" icon="close"
                                               @click="clearDateRange"></vs-button>
                                </div>
                            </vs-col>
                            <vs-col vs-align="center" vs-w="4">
                                <div class="ml-4 mt-3 flex">
                                    <vs-switch color="success" v-model="with_finished_documents"
                                               @change.native="fetchData(false, true, false, false )"/>
                                    <p class="ml-2">Erledigte Transaktionen anzeigen </p>
                                </div>
                            </vs-col>
                        </vs-row>
                        <vs-row class="mt-2">
                            <vs-col vs-align="left" class="pt-1" vs-w="1"><small>Filtern nach: </small></vs-col>
                            <vs-col vs-align="left" class="flex" vs-w="3">
                                <div v-for="user in responsibleUsers" class="grid-item" >
                                    <vx-tooltip :text="user.detail.first_name + ' ' + user.detail.last_name">
                                        <gravatar :email="user.email" class="rounded-full shadow-md  w-8 mr-3 hover-curser gravatar " :class="selectedUserFilteredClass(user)" @click="selectUserFilter(user)" />
                                    </vx-tooltip>
                                </div>
                            </vs-col>

                          <vs-col vs-align="left" class="pl-6 pt-4" vs-w="2"><small>Bankkonto filtern: </small></vs-col>
                          <vs-col vs-align="left" class="flex" vs-w="6">
                            <mg-select v-model="selectedBankAccountId" :options="bankAccounts" name="bank_account"
                                       @select="onBankAccountChange"  @remove="onBankAccountChange"
                                       placeholder="Kein Bankkonto ausgewählt"
                                       track-by="id" label="label" >
                            </mg-select>
                          </vs-col>
                        </vs-row>
                    </div>


                    <div class="result-container">
                        <vs-alert v-show="data.length === 0" color="warning" icon="info">
                            <span>Kein Kontoauszug für diese Filterung/Auswahl gefunden.</span>
                        </vs-alert>

                        <drop v-for="(item, i) in data" class="drop"
                              @drop="handleDrop(item, ...arguments)"
                        >
                            <vs-row class="result-item mt-2 p-2"
                                    v-bind:class="[item.finished_document_allocation == '1' ? 'is-finished' : '']">


                                <vs-col vs-type="" vs-justify="left" vs-align="left" vs-w="2">
                                    <span class="d-block">{{ formatDate(item.booking_date) }}</span>

                                    <div class="responsible-user flex mt-3" v-if="item.responsible_user_id">
                                        <vx-tooltip :text="item.responsible_user.name">
                                            <gravatar :email="item.responsible_user.email" class="rounded-full shadow-md  h-6 w-6 mr-1 gravatar " />
                                        </vx-tooltip>
                                    </div>


                                  <vx-tooltip v-if="!selectedBankAccountId" :text="bankAccountType(item.bank_account_id).label" class="w-1" position="bottom">
                                    <div  :class="['badge-type', bankAccountType(item.bank_account_id).type]">
                                      {{bankAccountType(item.bank_account_id).type}}
                                    </div>
                                  </vx-tooltip>
                                </vs-col>

                                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="8" class="block">
                                    <div>
                                        <b>{{ item.recipient }}</b>
                                        <b><span v-if="item.client_id" class="link"
                                                 @click="filterSelectedClient(item.client_id)"> Lieferant/Kunde filtern</span>


                                          <div class="promo-link-container" v-if="item.client && item.client.supplier_invoice_portal_instructions && item.client.supplier_invoice_portal_instructions.length > 3">
                                            <vx-tooltip  position="top" >
                                              <span class="promo-link" @dblclick="toggleInvoiceInfoEdit(item)">Rechnungshilfe!</span>
                                              <template v-slot:text>
                                                {{item.client.supplier_invoice_portal_instructions}}
                                              </template>
                                            </vx-tooltip>
                                          </div>
                                          <div v-else-if="item.client" class="no-invoice-info">
                                            <span class="link" v-if="!item.isEditingInvoiceInfo" @dblclick="toggleInvoiceInfoEdit(item)">Keine Rechnungshilfe</span>
                                          </div>

                                          <div v-if="item.isEditingInvoiceInfo">
                                            <small class="mt-2 block">Rechnungshilfe für <u>{{item.client.company_name}}</u></small>
                                            <vs-textarea v-model="item.newInvoiceInstructions" type="text" placeholder="Rechnungsinfo hinzufügen" class="input-class" />
                                            <vs-button @click="saveInvoiceInstructions(item)" class="save-button">Save</vs-button>
                                            <vs-button @click="cancelInvoiceInstructions(item)" type="border" class="ml-2">Cancel</vs-button>
                                          </div>

                                        </b>
                                        <br>
                                        <p>{{ item.reference_text }}</p>
                                        <p v-if="item.internal_notice && item.id != inlineEditComment"
                                           class="internal-notice" style="white-space: pre;">
                                            {{ item.internal_notice }}</p>
                                        <div class="internal-notice-button" v-if="item.id != inlineEditComment">
                                            <vx-tooltip text="interner Hinweis hinzufügen">
                                                <feather-icon icon="Edit3Icon" class="inline-block ml-2"
                                                              svgClasses="w-4 h-4"
                                                              @click="inlineEditInternalNotice(item)"/>
                                            </vx-tooltip>
                                        </div>


                                        <vs-row class="internal-notice-input" v-if="item.id == inlineEditComment">
                                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12"
                                                    class="mb-0">
                                                <div class="vs-component vs-con-textarea w-full mb-0 vs-textarea-primary" style="border: 1px solid rgba(0, 0, 0, 0.08);">
                                                    <textarea v-model="item.internal_notice" @keyup="resizeTextarea()" @focus="resizeTextarea()"
                                                              class="w-full mb-0 textarea-auto-resize vs-textarea " ref="textarea" rows="1"
                                                              name="internal-notice"></textarea>
                                                </div>
                                            </vs-col>
                                            <vs-row>
                                                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3"
                                                        class="mb-0 pt-4">
                                                    Verantwortlich:
                                                </vs-col>
                                                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9"
                                                        class="mb-0">
                                                    <vs-select v-model="item.responsible_user_id"
                                                               class="w-full mt-2 small">
                                                        <vs-select-item :key="item.id" :value="item.id"
                                                                        :text="item.name"
                                                                        v-for="(item, index) in responsibleUsers"/>
                                                        <vs-select-item :value=null text="Keine Zuweisung"/>
                                                    </vs-select>
                                                </vs-col>
                                            </vs-row>

                                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12"
                                                    class="mt-2">
                                                <vs-button size="small" class="ml-0"
                                                           @click="saveTransactionComment(item)">Speichern
                                                </vs-button>
                                                <vs-button size="small" type="border" class="ml-3 "
                                                           @click="closeInlineEditInternalNotice()">
                                                    Abbrechen
                                                </vs-button>
                                            </vs-col>
                                        </vs-row>

                                      <vs-row>
                                      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2">
                                        <vs-button v-if="isCashAccount(item)" icon="delete" color="danger" @click="deleteTransaction(item.id)" size="small">
                                          Löschen
                                        </vs-button>
                                      </vs-col>
                                      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="2">
                                        <vs-button v-if="isCashAccount(item)" icon="edit" color="primary" @click="openEditModal(item)" size="small">
                                          Bearbeiten
                                        </vs-button>
                                      </vs-col>
                                    </vs-row>





                                      <div v-if="item.bank_transaction_documents && item.bank_transaction_documents.length >= 1"
                                             class="transaction-document-list">

                                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4"
                                                    class="document-item mt-2 p-2"
                                                    v-for="(file, i) in item.bank_transaction_documents">
                                                <div class="document-link">
                                                    <div>
                                                        <div class="badges-container">
                                                            <vs-icon icon="check_circle" class="check_circle"
                                                                     v-if="!hasBanktransactionDocumentError(file, item)"></vs-icon>

                                                            <vx-tooltip
                                                                    :text="banktransactionDocumentErrorMsg(file, item)"
                                                                    class="inline-block">
                                                                <vs-icon icon="cancel" class="cancel color-red"
                                                                         v-if="hasBanktransactionDocumentError(file, item)"></vs-icon>
                                                            </vx-tooltip>
                                                            <vs-icon icon="search" class="search pointer"
                                                                     @click="openDocumentViewer(file)"></vs-icon>
                                                            <vs-icon icon="cancel" class="cancel pointer"
                                                                     @click="disconnectDocument2Transaction(file)"></vs-icon>

                                                        </div>
                                                        <div class="image-container">
                                                            <img :src="file.file_thumb_url">
                                                        </div>

                                                        <div class="info-container">
                                                            <span class="filename">{{ file.original_filename }}</span>
                                                            <i class="sum" v-if="file.sum_brutto">{{ file.sum_brutto }}
                                                                €</i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </vs-col>
                                        </div>
                                    </div>

                                </vs-col>

                                <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="2"
                                        class="currency pt-2">
                                    <div v-bind:class="[item.booking_type == '-' ? 'minus' : 'plus']">
                                        <span v-if="item.booking_type == '-'">-</span>
                                        {{ item.sum }} €

                                      <div class="inline-block" v-if="!item.finished_document_allocation">
                                        <div class="badge-container">
                                          <!-- Wahrscheinlichkeit Badge -->
                                          <span class="probability-badge" v-if="item.document_suggestions && item.document_suggestions.length">
                                              {{ item.document_suggestions[0].probability }}%
                                              ({{ item.document_suggestions.length }})
                                          </span>

                                          <!-- Tooltip mit Tabelle -->
                                          <div class="tooltip">
                                            <div class="tooltip-content">
                                              <table class="suggestion-table" v-if="item.document_suggestions && item.document_suggestions.length">
                                                <thead>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Wahrscheinlichkeit</th>
                                                  <th>Hinweis</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(suggestion, index) in item.document_suggestions" :key="index">
                                                  <td>#{{ index + 1 }}</td>
                                                  <td>{{ suggestion.probability }}%</td>
                                                  <td>{{ suggestion.hint }}</td>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="inline-block mr-2 link searchDocumentSumIcon" v-if="!item.finished_document_allocation">
                                        <vx-tooltip text="Intelligente Suche" position="top">
                                          <feather-icon icon="SearchIcon" svgClasses="w-6 h-6"
                                                        @click="intelligentDocumentSearch(item.id)"/>
                                        </vx-tooltip>
                                      </div>


                                      <i v-if="item.finished_document_allocation == '0' && item.bank_transaction_documents.length >= 1"
                                           class="alreadyAllocatedSum">Bereits zugew.:
                                            {{ getTransactionDocumentsSum(item) }} €</i>
                                        <i v-if="item.finished_document_allocation == '0' && item.bank_transaction_documents.length >= 1"
                                           class="alreadyAllocatedSum"
                                           :class="getTransactionDocumentsSumDifferenceToBankTransaction(item) === 0?'color-green':'color-red'">Diff
                                            Betrag: {{
                                            getTransactionDocumentsSumDifferenceToBankTransaction(item).toLocaleString('de-DE')
                                            }}
                                            €</i>
                                        <vs-button color="dark" type="filled" icon="check"
                                                   v-if="item.finished_document_allocation == '0'"
                                                   size="small" class="finishedDocumentAllocationButton"
                                                   @click="clickFinishedDocumentAllocationButton(item)">Auf erledigt
                                            setzen
                                        </vs-button>
                                        <vs-button color="dark" type="filled" icon="check"
                                                   v-if="item.finished_document_allocation"
                                                   size="small" class="finishedDocumentAllocationButton"
                                                   @click="clickNotFinishedDocumentAllocationButton(item)">Auf nicht
                                            erledigt setzen
                                        </vs-button>
                                    </div>
                                </vs-col>

                            </vs-row>
                        </drop>
                    </div>
                    <button @click="fetchData"
                            class="mt-5 mr-3 vs-component vs-button vs-button-primary vs-button-border">Daten
                        neuladen
                    </button>

                    <button @click="exportPdf" class="mt-5 mr-3 vs-component vs-button vs-button-primary vs-button-border">
                        Export-Dateien generieren
                    </button>

                  <button @click="openModalCreateCashTransaction" class="mt-5  vs-component vs-button vs-button-primary vs-button-border">
                    + Neue Barkasse Buchung
                  </button>

                  <button @click="generateSuggestions" class="mt-5 mr-3 vs-component vs-button vs-button-primary vs-button-border">
                    Belegvorschläge generieren
                  </button>

                  <create-bank-transaction-modal :active.sync="createCashTransactionModalActive" @close="closeModalCreateCashTransaction()" @saved="closeModalCreateCashTransaction(true)"
                                         :bankTransaction.sync="editedBankTransaction"></create-bank-transaction-modal>
                </vx-card>
            </vs-row>
            <vs-row v-if="mergedPdfUrl || generatedCSVUrl|| generatedZIPUrl">
                <vx-card title="Erfolgreich Generierte Dokumente">
                    <div class="mt-5">
                        <ul>
                            <li v-if="generatedZIPUrl"><a :href="generatedZIPUrl" target="_blank" class="">Download der
                                Belege als ZIP-Datei</a></li>
                            <li v-if="generatedCSVUrl"><a :href="generatedCSVUrl" target="_blank">Download der
                                Bank-Transaktionen incl. Kommentare als CSV-Datei</a></li>
                            <li v-if="mergedPdfUrl"><a :href="mergedPdfUrl" target="_blank">Download aller Dokumente als
                                eine PDF-Datei</a></li>
                        </ul>
                    </div>
                </vx-card>
            </vs-row>


        </vs-col>
        <vs-col vs-type="flex" vs-justify="top" vs-align="top" vs-w="5" class="document-mapping-list">
            <vx-card>
                <h1>Dokumente</h1>
                <div class="documents-fixed" id="documents-fixed">
                    <vs-row>
                        <vs-col vs-align="center" vs-w="5">
                            <div class="form-group has-clear-right">
                                <input type="text" placeholder="Suchen..." v-model="searchDocumentInput"
                                       class="form-control mr-2 ml-2"
                                       v-on:keyup.enter="fetchData">
                                <vs-button color="primary" type="border" icon="search" @click="fetchData"></vs-button>
                            </div>
                        </vs-col>
                        <vs-col vs-align="center" vs-w="7">
                            <div class="form-group has-clear-right">
                                <mg-select v-model="searchDocumentInputClientId" :options="clients" name="client_id"
                                           placeholder="Lieferant/Kunde filtern"
                                           track-by="id" label="company_name" data-vv-as="Kunde/Lieferant" class="mr-2">
                                </mg-select>
                                <vs-icon icon="cancel" class="error-icon pointer multiselect-clear-icon"
                                         @click="[searchDocumentInputClientId = null, fetchData()]"
                                         v-if="searchDocumentInputClientId"></vs-icon>
                                <vs-button color="primary" type="border" icon="search" @click="fetchData"></vs-button>
                            </div>

                        </vs-col>
                    </vs-row>

                    <vs-alert color="#7795D1" :active="true" icon="info" class="mb-4 mt-4 ml-2 "
                              v-show="documents.length == 0">
                        Keine Dokumente gefunden
                    </vs-alert>

                    <div class="scrollable">

                        <vs-popup fullscreen :title="openDocumentViewerData.original_filename"
                                  :active.sync="isOpenDocumentViewer">
                            <vs-row class="popup-document-content">
                                <vs-col vs-type="flex" vs-justify="top" vs-align="top" vs-w="10">
                                    <div class="text-center documentViewer"
                                         :class="isOpenDocumentViewer100PercentHeight == true ?'max100percent':''">
                                        <img :src="openDocumentViewerData.file_thumb_url">
                                    </div>
                                </vs-col>

                                <vs-col vs-w="2" class="popup-document-detail">
                                    <vs-button @click="isOpenDocumentViewer100PercentHeight = true" class="mb-3"
                                               v-if="isOpenDocumentViewer100PercentHeight === false">Dokument auf Höhe
                                        begrenzen
                                    </vs-button>
                                    <vs-button @click="isOpenDocumentViewer100PercentHeight = false" class="mb-3"
                                               v-if="isOpenDocumentViewer100PercentHeight === true">Dokument auf Höhe
                                        <b>nicht</b>
                                        begrenzen
                                    </vs-button>
                                    <strong class="document_date" v-if="openDocumentViewerData.document_date"><small>Belegdatum: </small>{{
                                        formatDate(openDocumentViewerData.document_date)
                                        }}</strong>
                                    <strong class="document_number"
                                            v-if="openDocumentViewerData.document_number"><small>Belegnummer:</small>
                                        {{ openDocumentViewerData.document_number }}</strong>
                                    <label class="company_name"
                                           v-if="openDocumentViewerData.client_id && openDocumentViewerData.client.company_name"><br><small>Lieferant/Kunde: </small><br>{{
                                        openDocumentViewerData.client.company_name
                                        }}</label>

                                    <i class="notice"
                                       v-if="openDocumentViewerData.notice"><br><small>Notiz: </small>{{
                                        openDocumentViewerData.notice
                                        }}</i>

                                    <span
                                            class="filename"> <br><small>Dateiname: </small>{{
                                        openDocumentViewerData.original_filename
                                        }}</span>
                                    <i class="sum" v-if="openDocumentViewerData.sum_brutto">Betrag brutto:
                                        {{ openDocumentViewerData.sum_brutto }} €</i>
                                    <i v-if="openDocumentViewerData.sum_netto">Betrag netto:
                                        {{ openDocumentViewerData.sum_netto }} €</i>

                                    <div class="mt-5">
                                        <a :href="openDocumentViewerData.file_url" target="_blank"
                                           referrerpolicy="origin"><b>Quelldatei PDF öffnen</b></a>
                                        <br>

                                        <a :href="'https://my-workspace.io/document-management/'+openDocumentViewerData.id"
                                           target="_blank"><b>Datei in der Belegverwaltung öffnen</b></a>
                                    </div>

                                </vs-col>
                            </vs-row>


                        </vs-popup>

                        <drag class="drag" :transfer-data="{ file }" :effect-allowed="['copy']" @dragend="over = null"   :class="{ 'highlight-100': file.probability === 100 }"
                              drop-effect="copy" v-for="file in documents">
                            <vs-col vs-justify="left" vs-align="left" vs-w="6" class="document-item p-2 ">
                                <div class="document-link">
                                    <div class="relative">
                                        <div class="badges-container">
                                            <vs-icon icon="check_circle" class="check_circle"
                                                     v-if="file.sum_brutto && file.document_date && file.document_number "></vs-icon>
                                            <vs-icon icon="cancel" class="cancel"
                                                     v-if="!file.sum_brutto || !file.document_date || !file.document_number "></vs-icon>
                                            <vs-icon icon="search" class="search"
                                                     @click="openDocumentViewer(file)"></vs-icon>
                                        </div>
                                      <!-- Neuer Badge-Container für rechts -->
                                      <div class="badges-container-right">
                                        <span v-if="file.probability" :class="getBadgeClass(file.probability)">
                                            {{ file.probability }}%
                                        </span>
                                        <span class="hint-badge" v-if="file.hint" :title="file.hint">
                                            {{ file.hint }}
                                        </span>
                                        <span
                                            class="hint-badge document-date-difference"
                                            v-if="file.document_date_difference"
                                            :class="getDateDifferenceClass(file.document_date_difference)"
                                            :title="file.document_date_difference">
                                            {{ file.document_date_difference }} Tage Unterschied
                                        </span>
                                      </div>
                                        <div class="image-container">
                                            <img :src="file.file_thumb_url">
                                        </div>

                                        <div class="info-container">

                                            <div class="info-hover-container">
                                                <strong class="document_date" v-if="file.document_date">Belegdatum:
                                                    {{ formatDate(file.document_date) }}</strong>
                                                <strong class="document_number" v-if="file.document_number">Belegnummer:
                                                    {{ file.document_number }}</strong>
                                                <label class="company_name"
                                                       v-if="file.client_id && file.client.company_name">{{
                                                    file.client.company_name
                                                    }}</label>
                                                <i class="notice" v-if="file.notice">{{ file.notice }}</i>
                                            </div>

                                            <span class="filename">{{ file.original_filename }}</span>
                                            <i class="sum" v-if="file.sum_brutto">{{ file.sum_brutto }} €</i>
                                        </div>

                                    </div>
                                </div>

                            </vs-col>

                            <div slot="image" class="drag-image">
                                <img :src="file.file_thumb_url">
                            </div>
                        </drag>

                    </div>
                </div>
            </vx-card>
        </vs-col>

        <mysql-selection-popup :active="activeMysqlSelectionPrompt"
                               @close="onMysqlSelectionPromptClose" @accept="fetchData()"></mysql-selection-popup>

    </vs-row>
</template>

<script>
import ApiService from "../../api";
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import MysqlSelectionPopup from "../../components/mysql-connection-popup/MysqlSelectionPopup";
import createBankTransactionModal from "../../components/bank/create-bank-transaction-modal.vue";

import QueryHelper from "../../mixins/helper/query.helper";
import findIndex from "lodash/findIndex";
import PriceHelper from "../../mixins/helper/price.helper";
import {Drag, Drop} from 'vue-drag-drop';
import MgSelect from "../../components/mg-select/MgSelect";


var qs = require('qs');

import vSelect from 'vue-select'
import {mapGetters} from "vuex";
import Gravatar from "vue-gravatar";
import CreateTransferModal from "@/components/billing-transfer/create-transfer-modal.vue";

export default {
    components: {
      CreateTransferModal,
        Gravatar,
        MysqlSelectionPopup,
        DateRangePicker,
        Drag, Drop, MgSelect,
        'v-select': vSelect,
        createBankTransactionModal
    },
    data() {
        return {
            over: false,
            overIndex: null,
            inlineEditComment: null,
            activeMysqlSelectionPrompt: false,
            data: [],
            documents: [],
            selectedFilteredUserId: null,
            years: [
                "2021",
                "2022",
                "2023",
                "2024",
                "2025",
                "2026",
            ],
            clients: [],
            queryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 9999,
                page: 1,
            },
            documentQueryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 9999,
                page: 1,
            },
            with_finished_documents: false,
            total_rows: 0,
            selectedForDialog: null,
            categories: [],
            searchInput: null,
            responsibleUsers: [],
            searchDocumentInput: null,
            searchDocumentInputClientId: null,
            opens: true,
            changedDateRange: false,
            dateRange: {
                startDate: null,
                endDate: null
            },
            isOpenDocumentViewer: false,
            isOpenDocumentViewer100PercentHeight: false,
            openDocumentViewerData: [],
            mergedPdfUrl: null,
            generatedZIPUrl: null,
            generatedCSVUrl: null,
            bankAccounts:[],
            selectedBankAccountId:null,
            createCashTransactionModalActive: false,
            editedBankTransaction: null,
        }
    },
    computed: {
        ...mapGetters(['userHasPermission']),
        months() {

            let months = [];
            for (let i = 1; i <= 12; i++) {
                if (i <= 9) {
                    i = "0" + i;
                }
                const date = moment(`${this.currentYear}-${i}-01`);
                months.push({
                    'name': date.format('MMMM'),
                    'month': i.toString()
                });
            }

            return months;
        },
        requestParams() {
            let queryParams = QueryHelper.parseRequestParams(this.queryParams);

            if (this.with_finished_documents == false || this.with_finished_documents == 0) {
                queryParams.filter.push({
                    field: 'finished_document_allocation',
                    value: '0',
                    expression: 'exact'
                });
            }
            if (this.selectedFilteredUserId) {
                queryParams.filter.push({
                    field: 'responsible_user_id',
                    value: this.selectedFilteredUserId,
                    expression: 'exact'
                });
            }

            if (this.dateRange.startDate != null) {
                queryParams.filter.push({
                    field: 'booking_date',
                    value: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    expression: 'greaterAndExact'
                });
                queryParams.filter.push({
                    field: 'booking_date',
                    value: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                    expression: 'lessAndExact'
                });
            }

            if (this.searchInput != null && this.searchInput.length >= 1) {
                queryParams.filter.push({
                    expression: 'group',
                    filter: [
                        {
                            field: 'recipient',
                            value: this.searchInput,
                            expression: 'contains',
                            operator: 'or'

                        },
                        {
                            field: 'client',
                            value: this.searchInput,
                            expression: 'contains',
                            operator: 'or'

                        },
                        {
                            field: 'reference_text',
                            value: this.searchInput,
                            expression: 'contains',
                            operator: 'or'
                        },
                        {
                            field: 'sum',
                            value: this.searchInput,
                            expression: 'contains',
                            operator: 'or'
                        }
                    ]
                });
            }

          if (this.selectedBankAccountId) {
            queryParams.filter.push({
              field: 'bank_account_id',
              value: this.selectedBankAccountId,
              expression: 'exact'
            });
          }

            queryParams.order =
                {
                    'field': 'booking_date',
                    'direction': 'desc'
                }
            ;

            return queryParams;
        },
      selectedBankAccount(){
        return this.bankAccounts.find(account => account.id === this.selectedBankAccountId) || null;

      },
        requestDocumentParams() {
            let queryParams = QueryHelper.parseRequestParams(this.documentQueryParams);

            queryParams.filter.push({
                field: 'bank_transaction_id',
                value: 'null',
                expression: 'exact'
            });

            queryParams.filter.push({
                field: 'sum_brutto',
                value: '',
                expression: 'different'
            });

            if (this.searchDocumentInput != null && this.searchDocumentInput.length >= 1) {
                queryParams.filter.push({
                    expression: 'group',
                    filter: [
                        {
                            field: 'document_number',
                            value: this.searchDocumentInput,
                            expression: 'contains',
                            operator: 'or'

                        },
                        {
                            field: 'notice',
                            value: this.searchDocumentInput,
                            expression: 'contains',
                            operator: 'or'
                        },
                        {
                            field: 'original_filename',
                            value: this.searchDocumentInput,
                            expression: 'contains',
                            operator: 'or'
                        },
                        {
                            field: 'sum_brutto',
                            value: this.searchDocumentInput,
                            expression: 'contains',
                            operator: 'or'
                        },
                        {
                            field: 'sum_netto',
                            value: this.searchDocumentInput,
                            expression: 'contains',
                            operator: 'or'
                        },
                        {
                            field: 'search_keywords',
                            value: this.searchInput,
                            expression: 'contains',
                            operator: 'or'
                        }
                    ]
                });
            }
            if (this.searchDocumentInputClientId) {
                queryParams.filter.push({
                    field: 'client_id',
                    value: this.searchDocumentInputClientId,
                    expression: 'exact',
                });
            }

            queryParams.order =
                {
                    'field': 'created_at',
                    'direction': 'desc'
                }
            ;

            return queryParams;
        },
        currentYear() {
            return this.$route.params.year;
        },
        currentMonth() {
            return this.$route.params.month;
        },
    },
    mounted() {
        if (!this.$route.params.year && !this.$route.params.month) {
            var d = new Date();
            // Set it to one month ago
            d.setMonth(d.getMonth() - 1);
            this.$router.push('/bank-transactions/' + (d.getFullYear()) + '/' + ("0" + (d.getMonth() + 1)).slice(-2))
        }
        this.registerKeyDownEventListenerSaveInlineEditInternalNotice();
    },
    created() {

        if (!this.userHasPermission('view_bank_transactions')) {
            console.log("no permission")

            this.$vs.notify({
                title: 'Keine Berechtigung',
                text: 'Für diese Seite hast du keine Berechtigung',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
            this.$router.push(`/`);
        }
        if (this.userHasPermission('view_bank_transactions')) {
            this.fetchData();

        }
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this._keyListener);
    },
    methods: {
        fetchData(presetDateRangeFilter = true, fetchBanktransactions = true, fetchBanktransactionsDocuments = true, fetchUsers = true) {



          if (presetDateRangeFilter) {
              this.$vs.loading();
              this.presetDateRangeFilter();
              this.$vs.loading.close();
          }

          if (fetchBanktransactions) {
            this.$vs.loading();
            ApiService.get('bank-transactions', {
              params: this.requestParams,
              paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then((response) => {
              this.data = response.data.result;
              this.$vs.loading.close();
            }).catch((error) => {
              this.$vs.loading.close();
              this.activeMysqlSelectionPrompt = true;
              this.$vs.notify({
                title: 'Fehler',
                text: 'Banktransaktionen konnten nicht geladen werden',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          }


          if (fetchBanktransactionsDocuments) {
            this.$vs.loading();
            ApiService.get('bank-transactions/document', {
              params: this.requestDocumentParams,
              paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then((response) => {
              this.documents = response.data.result;
              this.$vs.loading.close();
            }).catch((error) => {
              this.$vs.loading.close();
              this.activeMysqlSelectionPrompt = true;
              this.$vs.notify({
                title: 'Fehler',
                text: 'Dokumente konnten nicht geladen werden',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          }

          if (!this.clients || this.clients.length === 0) {
            this.$vs.loading();
            this.fetchClients();
            this.$vs.loading.close();
          }
          if (!this.bankAccounts || this.bankAccounts.length === 0) {
            this.fetchBankAccounts();
          }
          if (fetchUsers) {
            this.$vs.loading();
            this.fetchResponsibleUsers();
            this.$vs.loading.close();
          }

        },
        fetchClients() {

            ApiService.get('clients/list')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.clients = response.data.result;
                    }
                });
        },
        setDragOverIndex(i) {
            this.overIndex = i;
            this.over = true;
        },
        activeDragOverClassName(i) {
            if (this.over === true && this.overIndex === i) {
                return "over";
            }
            return null
        },
        presetDateRangeFilter() {
            const startDate = moment(`${this.currentYear}-${this.currentMonth}-01`);
            this.dateRange.startDate = startDate.format('YYYY-MM-DD')

            const endDate = moment(`${this.currentYear}-${this.currentMonth}-01`).endOf('month');
            this.dateRange.endDate = endDate.format('YYYY-MM-DD')
            // this.dateRange.endDate =add(1, 'day')


        },
        formatDateTime(value) {
            return moment(new Date(value)).format('DD.MM.YYYY - H:m')
        },
        formatDate(value) {
            return moment(new Date(value)).format('DD.MM.YYYY')
        },
        parseCurrency(value) {
            return PriceHelper.formatCurrency(value)
        },
        floatPointToCommaCurrency(value) {
            return PriceHelper.numberWithCommas(value)
        },
        loadMoreTransactions(value) {
            this.config.per_page = this.config.per_page + value;
        },
        updateValues(data) {
            this.$emit('update-date-range', {
                start: moment(data.startDate).format('YYYY-MM-DD'),
                end: moment(data.endDate).add(1, 'day').format('YYYY-MM-DD')
            });
            this.changedDateRange = true;
            this.fetchData();
        },
        checkOpen(data) {
        },
        inlineEditInternalNotice(item) {
            this.inlineEditComment = item.id;
            // timeout 200ms
            setTimeout(() => {
                this.resizeTextarea();
            }, 200);
        },
        saveCurrentEditedInternalNote() {
            if (this.inlineEditComment) {
                const item = this.data.find(item => item.id === this.inlineEditComment);
                this.saveTransactionComment(item);
            }
        },
        registerKeyDownEventListenerSaveInlineEditInternalNotice() {
            this._keyListener = function (e) {
                if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault(); // present "Save Page" from getting triggered.
                    console.log("Strg + Enter wurde gedrückt!");
                    this.saveCurrentEditedInternalNote();
                }
            };

            document.addEventListener('keydown', this._keyListener.bind(this));
        },
        closeInlineEditInternalNotice() {
            this.inlineEditComment = null;
        },
        clearDateRange() {
            this.dateRange = {
                startDate: null,
                endDate: null
            };

            this.$emit('update-date-range', {
                start: null,
                end: null
            });

            this.fetchData()
        },
        getTransactionDocumentsSum(transaction) {
            var sum = 0;
            for (var i = 0; i < transaction.bank_transaction_documents.length; i++) {
                sum = +sum + +transaction.bank_transaction_documents[i].sum_brutto
            }
            return sum;
        },
        getTransactionDocumentsSumDifferenceToBankTransaction(transaction) {
            var sum = 0;
            for (var i = 0; i < transaction.bank_transaction_documents.length; i++) {
                sum = +sum + +transaction.bank_transaction_documents[i].sum_brutto
            }

            return (sum - parseFloat(transaction.sum.replace(',', '.').replace(' ', '')))
        },

        handleDrop(toItem, data) {
            console.log(toItem.id);
            console.log(`You dropped with data: ${JSON.stringify(data.file.id)}`);
            this.saveDocumentData(data.file.id, toItem.id);
        },
        saveDocumentData(documentId, transactionId) {

            if (documentId && transactionId) {

                let payload = {bank_transaction_id: transactionId};

                ApiService.put('bank-transactions/document/' + documentId, payload)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.fetchData();
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Dokument erfolgreich zugeordnet',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            })
                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Dokument konnte nicht zugeordnet werden',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Dokument konnte nicht zugeordnet werden',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
            }
        },
        saveTransactionComment(item) {
            let payload = {
                internal_notice: item.internal_notice,
                responsible_user_id: item.responsible_user_id
            };

            ApiService.put('bank-transactions/' + item.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.inlineEditComment = null;
                        this.fetchData();
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Kommentar hinterlegt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Kommentar konnte nicht hinterlegt werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Kommentar konnte nicht hinterlegt werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
        },

        clickFinishedDocumentAllocationButton(item) {
            let payload = {finished_document_allocation: 1};

            ApiService.put('bank-transactions/' + item.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchData(false, true, false, false);
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Zuweisung abgeschlossen',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Dokument-Zuweisung konnte nicht abgeschlossen werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Dokument-Zuweisung konnte nicht abgeschlossen werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
        },
        clickNotFinishedDocumentAllocationButton(item) {
            let payload = {finished_document_allocation: 0};

            ApiService.put('bank-transactions/' + item.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchData(false, true, false, false);
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Dokument auf nicht erledigt gesetzt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Dokument konnte nicht geändert werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Dokument-Zuweisung konnte nicht geändert werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
        },
        openDocumentViewer(file) {
            this.openDocumentViewerData = file;
            this.isOpenDocumentViewer = true;

        },
        banktransactionDocumentErrorMsg(file, item) {
            let msg = "";

            if (!file.sum_brutto || !file.document_date || !file.document_number) {
                msg = msg + "Beleginformationen fehlen. \n";
            }
            var document_date = moment(file.document_date);
            if (!document_date.isValid()) {
                msg = msg + "Belegdatum ist nicht korrekt. Bitte prüfen!! \n";
            }

            if (moment(item.booking_date).isBefore(document_date)) {
                msg = msg + "Das Transaktionsdatum liegt vor dem Belegdatum. Bitte prüfen!! \n";
            }

            return msg;

        },
        hasBanktransactionDocumentError(file, item) {

            if (!file.sum_brutto || !file.document_date || !file.document_number) {
                return true;
            }

            var document_date = moment(file.document_date);
            if (!document_date.isValid()) {
                return true;
            }

            if (moment(item.booking_date).isBefore(document_date)) {
                return true;
            }

            return false;
        },
        filterSelectedClient(clientId) {
            this.searchDocumentInputClientId = clientId;
            this.searchDocumentInput = "";
            this.fetchData(false, false, true, false);
        },

        intelligentDocumentSearch(id) {
          this.$vs.loading();
          ApiService.get('bank-transactions/document/intelligent-search/'+id).then((response) => {
            this.documents = response.data.result;
            this.$vs.loading.close();
          }).catch((error) => {
            this.$vs.loading.close();
            this.documents = [];
            this.$vs.notify({
              title: 'Fehler',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        },
        disconnectDocument2Transaction(file) {
            let payload = {bank_transaction_id: null};

            ApiService.put('bank-transactions/document/' + file.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchData(false, true, true, false);
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Dokumentenverbindung erfolgreich entfernt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Dokument konnte nicht geändert werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Dokument-Zuweisung konnte nicht geändert werden',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
        },
      openModalCreateCashTransaction() {
        this.createCashTransactionModalActive = true;
      },
      closeModalCreateCashTransaction(saved = false) {
        this.createCashTransactionModalActive = false;
        this.fetchData(false,true,false,false)
        if (saved) {
          this.fetchData(false,true,false,false)
        }

      },
      generateSuggestions() {
        this.$vs.loading();
        ApiService.get('bank-transactions/assign-document-suggestions').then((response) => {
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Vorschläge erfolgreich generiert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
          this.fetchData(false, true, false, false);
          this.$vs.loading.close();
        }).catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Fehler',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
      },
        exportPdf() {
            this.$vs.loading();
            let bankTransactionsPromise = ApiService.get('bank-transactions/mergePdf', {
                params: this.requestParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            });

            Promise.all([bankTransactionsPromise])
                .then(([bankTransactions]) => {
                    this.$vs.loading.close();
                    if (bankTransactions.data.status === 'success') {
                        this.mergedPdfUrl = bankTransactions.data.download_link;
                        this.generatedZIPUrl = bankTransactions.data.zip_link;
                        this.generatedCSVUrl = bankTransactions.data.csv_link;

                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'PDF-Datei erfolgreich erstellt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.loading.close();
                });
        },
        onMysqlSelectionPromptClose() {
            this.activeMysqlSelectionPrompt = false;
            this.fetchData();
        },

        onChangeYear(year) {
            this.changedDateRange = false;
            const currentYear = this.$route.params.year;

            if (year === currentYear) {
                return;
            }

            this.$router.push({
                name: 'bank-transactions-month-year', params: {
                    year: year,
                    month: '01'
                }
            });
        },

        onChangeMonth(month) {
            this.changedDateRange = false;
            const currentMonth = this.$route.params.month;

            if (month.month === currentMonth) {
                return;
            }

            this.$router.push({
                name: 'bank-transactions-month-year', params: {
                    year: this.$route.params.year,
                    month: month.month
                }
            });
        },
        fetchResponsibleUsers() {

            let queryParams = {
                filter: [
                    {
                        field: 'roles.name',
                        expression: 'exact',
                        value: 'super-admin'
                    },
                    {
                        field: 'typ',
                        expression: 'exact',
                        value: 'employee'
                    }
                ],
            };

            this.$vs.loading()
            ApiService.get('users', {
                params: queryParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                this.responsibleUsers = response.data.result;
                this.$vs.loading.close()
            }).catch(response => {
                this.$vs.loading.close()
                this.$vs.notify({
                    title: 'Error',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })

        },
        selectedUserFilteredClass(user) {
            if (this.selectedFilteredUserId == user.id) {
                return 'selected-filtered-user';
            }
            return '';
        },
        selectUserFilter(user) {
            if (this.selectedFilteredUserId == user.id) {
                this.selectedFilteredUserId = null;
            } else {
                this.selectedFilteredUserId = user.id;
            }
            this.$vs.loading();
            this.fetchData(false, true, false, false);
        },
        resizeTextarea() {
            for (const element of this.$el.querySelectorAll('.textarea-auto-resize')) {
                element.style.height = element.scrollHeight - 4 + 'px';
            }
        },
      toggleInvoiceInfoEdit(item) {

        this.$set(item, 'isEditingInvoiceInfo', !item.isEditingInvoiceInfo);

        if (!item.newInvoiceInstructions || item.newInvoiceInstructions === '') {
          console.log('set new instructions',item.newInvoiceInstructions,item.client.supplier_invoice_portal_instructions);
          item.newInvoiceInstructions = item.client ? item.client.supplier_invoice_portal_instructions : '';
        }
        console.log('toggleInvoiceInfoEdit',item.newInvoiceInstructions);
      },
      saveInvoiceInstructions(item) {

        if (!item.client) {
          this.$vs.notify({
            title: 'Error',
            text: 'Kein Kunde hinter dieser Transaktion gefunden',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }

        this.saveClient(item).then(() => {
          item.client.supplier_invoice_portal_instructions = item.newInvoiceInstructions;
          item.isEditingInvoiceInfo = false;
        });
      },
      cancelInvoiceInstructions(item) {
        item.newInvoiceInstructions = item.client && item.client.supplier_invoice_portal_instructions ? item.client.supplier_invoice_portal_instructions : '';
        item.isEditingInvoiceInfo = false;
      },

      saveClient(item) {
        let payload = {
          id: item.client.id,
          supplier_invoice_portal_instructions: item.newInvoiceInstructions
        };


       return ApiService.put('clients/' + item.client.id, payload)
            .then((response) => {
              if(response.data.status === 'success') {
                return this.$vs.notify({
                  title: 'Erfolgreich',
                  text: 'Der Lieferant wurde erfolgreich bearbeitet',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }

              this.$vs.notify({
                title: 'Fehlgeschlagen',
                text: 'Der Lieferant konnte nicht bearbeitet werden.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
            .catch((error) => {
              this.$vs.notify({
                title: 'Fehlgeschlagen',
                text: 'Der Lieferant konnte nicht bearbeitet werden.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
      },
      bankAccountType(id){
        return this.bankAccounts.find(account => account.id === id) || null;

      },
      isCashAccount(transaction) {
        const bankAccount = this.bankAccounts.find(account => account.id === transaction.bank_account_id);
        return bankAccount && bankAccount.type === 'cash';
      },
      deleteTransaction(transactionId) {

        ApiService.delete('bank-transactions/'+transactionId ).then(response => {
          if (response.data.status === "success") {
            this.$vs.notify({
              title: "Erfolgreich",
              text: "Die Transaktion wurde erfolgreich gelöscht.",
              color: "success"
            });
            this.fetchData(false,true,false,false);
          } else {
            this.$vs.notify({
              title: "Fehler",
              text: "Die Transaktion konnte nicht gelöscht werden.",
              color: "danger"
            });
          }
        }).catch(error => {
          this.$vs.notify({
            title: "Fehler",
            text: error.message,
            color: "danger"
          });
        });
      },

      openEditModal(transaction) {

        this.openModalCreateCashTransaction();
        this.editedBankTransaction = transaction;
      },
      async fetchBankAccounts() {
        try {
          const response = await  ApiService.get('/bank-account');
          if (response.data.status === 'success') {
            this.bankAccounts = response.data.result;
          }
        } catch (error) {
          console.error('Fehler beim Abrufen der Bankkonten:', error);
        }
      },

      onBankAccountChange() {
          console.log("test")
        console.log(this.selectedBankAccountId)
        this.$nextTick(() => {
          console.log("tes2t")
          console.log(this.selectedBankAccountId)
          this.fetchData(false,true,false,false); // fetchData erst ausführen, nachdem der DOM aktualisiert wurde
        });
      },
      getBadgeClass(probability) {
        if (probability >= 90) {
          return 'badge-high';
        } else if (probability >= 50) {
          return 'badge-medium';
        } else {
          return 'badge-low';
        }
      },
      getDateDifferenceClass(dateDifference) {
        if (dateDifference < 12) {
          return 'green-badge';
        } else if (dateDifference <= 40) {
          return 'orange-badge';
        } else {
          return 'red-badge';
        }
      }


    },
    watch: {
        '$route.params.month'(newId, oldId) {
            this.fetchData(true, true, false, false)
        },
        '$route.params.year'(newId, oldId) {
            this.fetchData(true, true, false, false)
        }
    },
    filters: {
        date: function (value) {
            if (value == null) return '';
            var f = function (d) {
                return d < 10 ? '0' + d : d;
            };
            return f(value.getDate()) + '.' + f(value.getMonth() + 1) + '.' + value.getFullYear();
        }
    }
}

</script>

<style lang="scss">
/* Bootstrap 4 text input with clear icon on the right */

.has-clear-right {
  position: relative;
}

.has-clear-right .form-control {
  //padding-right: 2.375rem;
}

.has-clear-right .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
}

.has-clear-right .form-control-feedback:hover {
  color: red;
}

.vue-daterange-picker {
  width: 81%;
  float: left;
  margin-right: 5px;
}

.daterangepicker .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.has-clear-right > input {
  width: 80%;
  float: left;
}

.currency > div {
  text-align: right;
  width: 100%;
}

.currency .plus {
  color: green;
  font-weight: bold;
  font-size: 15px;
}

.currency .minus {
  color: red;
  font-weight: bold;
  font-size: 18px;
}

.drop:nth-child(odd) .result-item {
  background-color: #f8f8f8;
}

.result-container {
  border-top: 1px solid #ecebeb;
  margin-top: 15px;
  padding-top: 15px;
}

.result-item.is-finished {
  opacity: 0.3;
  background: #e8ffd7;
}

.document-item {
  text-align: center;
  overflow: hidden;
}

.alreadyAllocatedSum {
  display: block;
  color: grey;
  font-size: 11px;
}

.finishedDocumentAllocationButton {
  font-size: 10px !important;
  width: 100%;
}

.result-item .finishedDocumentAllocationButton {
  opacity: 0;
}

.result-item:hover .finishedDocumentAllocationButton {
  opacity: 1;
}

.result-item .internal-notice-button {
  opacity: 0;
  width: 30px;
  cursor: pointer;
}

.result-item:hover .internal-notice-button {
  opacity: 1;
}

.transaction-document-list {
  display: block;
}

.drag, .drop {
  box-sizing: border-box;
  display: block;
  position: relative;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.image-container {
  max-width: 100%;
  height: 220px;
  overflow: hidden;
}

.badges-container {
  position: absolute;
}

.badges-container .vs-icon {
  font-size: 32px;
}

.document-link > div {
  border: 1px dashed #d0d0d0;
  height: 300px;
  overflow: hidden;
}


span.filename {
  display: block;
}

i.sum {
  margin-top: 10px;
  display: block;
  color: black;
  font-weight: 600;
}

.badges-container .check_circle {
  color: #10cc10;
}

.scrollable {
  max-height: 72vh;
  overflow-y: scroll;
}

.documentViewer {
  height: 100%;
}

.documentViewer img {
  height: 100%;
}

.con-vs-popup.fullscreen .vs-popup--content {
  height: 100%;
}

.drag-image {
  display: none;
}

p.internal-notice {
  color: red;
  font-style: italic;
}

.document-mapping-list {

  .has-clear-right > input {
    width: 70%;
  }

  .has-clear-right > .multiselect {
    width: 70%;
    float: left;
  }

  .has-clear-right .multiselect__tags {
    min-height: 30px !important;
    height: 38px;
  }


  span.multiselect__placeholder {
    font-size: 13px;
  }

  li.multiselect__element span {
    font-size: 12px;
  }

  .multiselect__input, .multiselect__single {
    font-size: 12px;
  }

}

.info-hover-container {
  position: absolute;
  background: #f8f8f8;
  width: 100%;
  height: 100px;
  font-size: 11px;
  display: none;
  padding-top: 10px
}

.info-container {
  position: relative;
}

.document-item:hover .info-hover-container {
  display: block;
}

.info-hover-container strong, .info-hover-container label, .info-hover-container i {
  display: block;
}

.info-hover-container .notice {
  margin-top: 5px;
}

.popup-document-detail {
  background: #f2f2f2;
  border-left: 1px solid #d7d7d7;
  padding-left: 10px;
  padding-top: 10px;

  strong, i, label {
    display: block;
  }
}

.vs-row.popup-document-content {
  position: relative;
  height: 100%;
}

.documents-fixed {
  position: fixed;
}

i.alreadyAllocatedSum.color-green {
  color: green;
}

i.alreadyAllocatedSum.color-red {
  color: red;
}

.documentViewer.max100percent {
  max-height: 85vh;
}

.cancel.color-red {
  color: red;
}

.multiselect-clear-icon {
  position: absolute;
  z-index: 555;
  margin-left: -21px;
  margin-top: -14px;
}

.multiselect-clear-icon:hover {
  color: red;
}

.months .menu-bar--list-item {
  padding: 1rem 0px;
}

.searchDocumentSumIcon {
  opacity: 0;
  cursor: pointer;
}

.vs-row.result-item:hover .searchDocumentSumIcon {
  opacity: 1;
}

.drop.over {
  border: 2px solid red;
  background: #ccc;
}
.hover-curser {
  cursor: pointer;
}
.gravatar.selected-filtered-user {
    outline: 3px solid #71c73f;
}
.promo-link-container {
  margin-left:10px;
  display: inline-block;
}

span.promo-link {
  color: #1dc8db;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}
.no-invoice-info {
  display: inline-block;
  background: #e3e3e3;
  margin-left: 5px;

}
.no-invoice-info .link {
  color: #767676;
  font-weight: 400;
  padding: 1px 2px;
  border-radius: 4px;

  font-size: 10px;
}

div.badge-type {
  background: #c9c9c9;
  height: 22px;
  position: relative;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  padding: 2px 9px;
  outline: 1px solid #bbbbbb;
}
.badge-type.visa {
  background-color:#ff9f43;
}
.badge-type.mastercard {
  background-color:#aedda0;
}



/**
Wahrscheinlichkeit
 */

.badges-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.badges-container-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  margin-left: auto;
  position: absolute;
  right: 0;
}

.badges-container-right span {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.badge-high {
  background-color: #4caf50; /* Grün für hohe Wahrscheinlichkeit */
  color: #fff;
}

.badge-medium {
  background-color: #ffc107; /* Gelb für mittlere Wahrscheinlichkeit */
  color: #000;
}

.badge-low {
  background-color: #f44336; /* Rot für niedrige Wahrscheinlichkeit */
  color: #fff;
}

.hint-badge {
  background-color: #2196f3; /* Blau für Hinweise */
  color: #fff;
  padding: 2px 6px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: normal;
  max-width: 150px; /* Begrenzung der Breite */
  overflow: hidden; /* Text wird abgeschnitten */
  text-overflow: ellipsis; /* ... wird angezeigt, wenn der Text zu lang ist */
  white-space: nowrap; /* Keine Zeilenumbrüche */
  cursor: pointer;
  position: relative;
}


.hint-badge:hover {
  z-index: 1000;
}

.drag {
  &.highlight-100 {
    background-color: rgba(76, 175, 80, 0.1); /* Leicht grün */
    border-radius: 8px; /* Optionale Abrundung */
    transition: background-color 0.3s ease-in-out; /* Sanfter Übergang */
  }
}

.badge-container {
  position: relative; /* Für den Tooltip */
  display: inline-block;
}

.probability-badge {
  display: inline-block;
  background-color: rgba(76, 175, 80, 0.1); /* Leicht grün */
  color: #4caf50; /* Grün für Text */
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer; /* Zeigt an, dass es interaktiv ist */
}

/* Tooltip-Styling */
.tooltip {
  position: absolute;
  top: 120%; /* Position unterhalb des Badges */
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  display: none; /* Standardmäßig ausgeblendet */
  z-index: 1000;
}

.tooltip-content {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 12px;
  text-align: left;
}

/* Tabelle im Tooltip */
.suggestion-table {
  width: 100%;
  border-collapse: collapse;
}

.suggestion-table th, .suggestion-table td {
  padding: 5px;
  border: 1px solid #ddd;
}

.suggestion-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-align: left;
}

.suggestion-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.suggestion-table tr:hover {
  background-color: rgba(76, 175, 80, 0.1); /* Leicht grün bei Hover */
}

/* Tooltip beim Hover anzeigen */
.badge-container:hover .tooltip {
  display: block; /* Tooltip sichtbar machen */
}

.hint-badge {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  color: #fff; /* Standardfarbe für Text */

  &.green-badge {
    background-color: #4caf50; /* Grün */
  }

  &.orange-badge {
    background-color: #ff9800; /* Orange */
  }

  &.red-badge {
    background-color: #f44336; /* Rot */
  }
}

</style>
